import { GraphQLErrorResponse } from '../gql/fetcher'
import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions, QueryFunctionContext } from 'react-query';
import { fetcher } from '../gql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Time: { input: string; output: string; }
};

export type AddOwnerToCapakeyInput = {
  capakey: Scalars['String']['input'];
  ownerID: Scalars['String']['input'];
};

export type AddUserToCapakeyInput = {
  capakey: Scalars['String']['input'];
  userID: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  number: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type Apa = {
  __typename?: 'Apa';
  fichelink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planid: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AutocompleteSuggestion = {
  __typename?: 'AutocompleteSuggestion';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AutocompleteSuggestionGroup = {
  __typename?: 'AutocompleteSuggestionGroup';
  suggestions: Array<AutocompleteSuggestion>;
};

export type AutocompleteSuggestions = {
  __typename?: 'AutocompleteSuggestions';
  districts: AutocompleteSuggestionGroup;
  municipalities: AutocompleteSuggestionGroup;
  provinces: AutocompleteSuggestionGroup;
};

export type Autocompleters = {
  __typename?: 'Autocompleters';
  listingIdByName?: Maybe<AutocompleteSuggestionGroup>;
  location?: Maybe<AutocompleteSuggestions>;
  naceCodes?: Maybe<AutocompleteSuggestionGroup>;
};


export type AutocompletersListingIdByNameArgs = {
  search: Scalars['String']['input'];
};


export type AutocompletersLocationArgs = {
  search: Scalars['String']['input'];
};


export type AutocompletersNaceCodesArgs = {
  search: Scalars['String']['input'];
};

/** The bounding box */
export type BoundingBoxCoordinates = {
  /** Latitude of the north east corner e.g. 51.0223633 */
  maxLat: Scalars['Float']['input'];
  /** Longitude of the north east corner e.g. 5.1718295 */
  maxLong: Scalars['Float']['input'];
  /** Latitude of the south west corner e.g. 51.2878928 */
  minLat: Scalars['Float']['input'];
  /** Longitude of the south west corner e.g. 3.6226895 */
  minLong: Scalars['Float']['input'];
};

export type Bpa = {
  __typename?: 'Bpa';
  fichelink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planid: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Branch = {
  __typename?: 'Branch';
  branchId: Scalars['String']['output'];
  houseNumber: Scalars['String']['output'];
  municipalityNl: Scalars['String']['output'];
  streetNl: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type Capakey = {
  __typename?: 'Capakey';
  capakey: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  omatScore?: Maybe<Scalars['Float']['output']>;
  zoningMapping: ZoneMappingType;
};

export type CapakeyEnvelope = {
  __typename?: 'CapakeyEnvelope';
  maxX: Scalars['Float']['output'];
  maxY: Scalars['Float']['output'];
  minX: Scalars['Float']['output'];
  minY: Scalars['Float']['output'];
};

export type CapakeyFilter = {
  buildingAreaEnd?: InputMaybe<Scalars['Float']['input']>;
  buildingAreaStart?: InputMaybe<Scalars['Float']['input']>;
  location?: InputMaybe<LocationFilter>;
  omatScoreEnd?: InputMaybe<Scalars['Float']['input']>;
  omatScoreStart?: InputMaybe<Scalars['Float']['input']>;
  parcelSizeEnd?: InputMaybe<Scalars['Float']['input']>;
  parcelSizeStart?: InputMaybe<Scalars['Float']['input']>;
  zoningMapping: Array<ZoneMappingType>;
};

export type CapakeyInfo = {
  __typename?: 'CapakeyInfo';
  address?: Maybe<Address>;
  apa?: Maybe<Array<Maybe<Apa>>>;
  bpa?: Maybe<Array<Maybe<Bpa>>>;
  buildingArea?: Maybe<Scalars['Float']['output']>;
  buildingVolume?: Maybe<Scalars['Float']['output']>;
  capakey: Scalars['String']['output'];
  crmID?: Maybe<Scalars['ID']['output']>;
  envelope?: Maybe<CapakeyEnvelope>;
  gemRup?: Maybe<Array<Maybe<GemRup>>>;
  gewRup?: Maybe<Array<Maybe<GewRup>>>;
  gwp?: Maybe<Array<Maybe<Gwp>>>;
  interactions: Array<CapakeyInteraction>;
  omatScore?: Maybe<Scalars['Float']['output']>;
  owners: Array<Contact>;
  parcelSize?: Maybe<Scalars['Float']['output']>;
  proRup?: Maybe<Array<Maybe<ProRup>>>;
  users: Array<Company>;
};

export type CapakeyInteraction = {
  __typename?: 'CapakeyInteraction';
  comment: Scalars['String']['output'];
  followUpDate?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  source: CapakeyInteractionSource;
  timestamp: Scalars['Time']['output'];
  type: Scalars['String']['output'];
  user: User;
};

export type CapakeyInteractionInput = {
  capakey: Scalars['String']['input'];
  comment: Scalars['String']['input'];
  followUpDate?: InputMaybe<Scalars['Time']['input']>;
};

export type CapakeyInteractionMarker = {
  __typename?: 'CapakeyInteractionMarker';
  capakey: Scalars['String']['output'];
  interaction: CapakeyInteraction;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  user: User;
};

export enum CapakeyInteractionSource {
  OwnerListing = 'OWNER_LISTING',
  Parcel = 'PARCEL',
  SuggestedListing = 'SUGGESTED_LISTING'
}

export type CapakeyMarkerFilter = {
  boundingBox: BoundingBoxCoordinates;
  includeProperties?: Scalars['Boolean']['input'];
};

export type CompaniesFilter = {
  companyBusinessTypeList?: InputMaybe<Array<CompanyBusinessType>>;
  companyStatusList?: InputMaybe<Array<SearchStatusType>>;
  companyVatList?: InputMaybe<Array<Scalars['String']['input']>>;
  employeesRange?: InputMaybe<EmployeesRange>;
  lastContactedInMonths?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<LocationFilter>;
  naceCodeList?: InputMaybe<Array<Scalars['String']['input']>>;
  realEstatePlayer?: InputMaybe<Array<RealEstatePlayerType>>;
  revenueRange?: InputMaybe<RevenueRange>;
};

export type Company = {
  __typename?: 'Company';
  branches: Array<Branch>;
  commercialName?: Maybe<Scalars['String']['output']>;
  contacts: Array<Contact>;
  crmUrl: Scalars['String']['output'];
  enterpriseName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  negotiator?: Maybe<User>;
  status: SearchStatusType;
  url: Scalars['String']['output'];
  vatNumber: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};


export type CompanyBranchesArgs = {
  filter?: InputMaybe<LocationFilter>;
};

export enum CompanyBusinessType {
  Horeca = 'HORECA',
  Industrial = 'INDUSTRIAL',
  Office = 'OFFICE',
  Retail = 'RETAIL',
  WarehouseLogistics = 'WAREHOUSE_LOGISTICS'
}

export type CompanyFeedback = {
  __typename?: 'CompanyFeedback';
  createdAt?: Maybe<Scalars['String']['output']>;
  excludedForever?: Maybe<Scalars['Int']['output']>;
  feedbackType: CompanyFeedbackType;
  userId?: Maybe<Scalars['String']['output']>;
  validUntil?: Maybe<Scalars['String']['output']>;
  vatNumber: Scalars['String']['output'];
};

export enum CompanyFeedbackType {
  Inactive = 'INACTIVE',
  ManagementHolding = 'MANAGEMENT_HOLDING',
  NeverMatch = 'NEVER_MATCH',
  NoInfo = 'NO_INFO'
}

export enum CompanySource {
  Proactive = 'PROACTIVE',
  Reactive = 'REACTIVE',
  Unknown = 'UNKNOWN',
  WebsiteInbound = 'WEBSITE_INBOUND'
}

export type Contact = {
  __typename?: 'Contact';
  companies: Array<Company>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  unsubscribed?: Maybe<Scalars['String']['output']>;
};

export type Coordinate = {
  Latitude: Scalars['Float']['input'];
  Longitude: Scalars['Float']['input'];
};

export type CreateCompany = {
  commercialName?: InputMaybe<Scalars['String']['input']>;
  enterpriseName?: InputMaybe<Scalars['String']['input']>;
  vatNumber: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyFeedback = {
  feedbackType: CompanyFeedbackType;
  vatNumber: Scalars['String']['input'];
};

export type CreateContact = {
  companyID?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  inquiryID?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInquiry = {
  name: Scalars['String']['input'];
  status?: InputMaybe<SearchStatusType>;
  vatNumber: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInteraction = {
  description?: InputMaybe<Scalars['String']['input']>;
  inquiryID: Scalars['String']['input'];
  propertyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateListingFeedback = {
  feedbackType: ListingFeedbackType;
  listingIds: Array<InputMaybe<Scalars['String']['input']>>;
  vatNumber: Scalars['String']['input'];
};

export type EmailContactInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type EmployeesRange = {
  EmployeesHigh: Scalars['Float']['input'];
  EmployeesLow: Scalars['Float']['input'];
};

export type GemRup = {
  __typename?: 'GemRup';
  fichelink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planid: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GewRup = {
  __typename?: 'GewRup';
  fichelink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planid: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Gwp = {
  __typename?: 'Gwp';
  fichelink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planid: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Inquiry = {
  __typename?: 'Inquiry';
  buildingSurface?: Maybe<Scalars['Float']['output']>;
  company?: Maybe<Company>;
  companyPage?: Maybe<Scalars['String']['output']>;
  contacts: Array<Contact>;
  extraRequirements?: Maybe<Scalars['String']['output']>;
  historicalListings: Array<PropertyListing>;
  id: Scalars['ID']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  land: Scalars['Boolean']['output'];
  lastContactedAt?: Maybe<Scalars['Time']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationZipcodes: Array<Scalars['String']['output']>;
  maxBuildingSurface?: Maybe<Scalars['Float']['output']>;
  maxPlotArea?: Maybe<Scalars['Float']['output']>;
  minBuildingSurface?: Maybe<Scalars['Float']['output']>;
  minPlotArea?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  negotiator?: Maybe<User>;
  optOut: Scalars['Boolean']['output'];
  plotArea?: Maybe<Scalars['Float']['output']>;
  propertySubType: Scalars['String']['output'];
  source?: Maybe<InquirySource>;
  status: SearchStatusType;
  transactionType: TransactionType;
  website?: Maybe<Scalars['String']['output']>;
};

export type InquiryParams = {
  __typename?: 'InquiryParams';
  buildingArea?: Maybe<Scalars['Int']['output']>;
  locations: Scalars['String']['output'];
  maxBuildingArea?: Maybe<Scalars['Int']['output']>;
  minBuildingArea?: Maybe<Scalars['Int']['output']>;
  propertySubType: Scalars['String']['output'];
  transactionType: TransactionType;
  zipCodes: Array<Scalars['String']['output']>;
};

export enum InquirySource {
  Proactive = 'PROACTIVE',
  Reactive = 'REACTIVE',
  Unknown = 'UNKNOWN',
  WebsiteInbound = 'WEBSITE_INBOUND'
}

export type Interaction = {
  __typename?: 'Interaction';
  date?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inquiries?: Maybe<Array<Inquiry>>;
  suggestedListings?: Maybe<Array<PropertyListing>>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  userName?: Maybe<Scalars['String']['output']>;
  vendorListings?: Maybe<Array<PropertyListing>>;
};

export type ListingFeedback = {
  __typename?: 'ListingFeedback';
  createdAt?: Maybe<Scalars['String']['output']>;
  feedbackType: ListingFeedbackType;
  listingIds: Array<Maybe<Scalars['String']['output']>>;
  userId?: Maybe<Scalars['String']['output']>;
  vatNumber: Scalars['String']['output'];
};

export enum ListingFeedbackType {
  Other = 'OTHER',
  PropertyTooBig = 'PROPERTY_TOO_BIG',
  PropertyTooSmall = 'PROPERTY_TOO_SMALL',
  WrongPropertyLocation = 'WRONG_PROPERTY_LOCATION',
  WrongPropertyType = 'WRONG_PROPERTY_TYPE'
}

export type ListingImage = {
  __typename?: 'ListingImage';
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type ListingUnit = {
  __typename?: 'ListingUnit';
  area: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type LocationFilter = {
  districtNlList?: InputMaybe<Array<Scalars['String']['input']>>;
  polygon?: InputMaybe<Array<Coordinate>>;
  provinceNlList?: InputMaybe<Array<Scalars['String']['input']>>;
  zipCodeList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LocationFilterImmoweb = {
  districtNlList?: InputMaybe<Array<Scalars['String']['input']>>;
  provinceNlList?: InputMaybe<Array<Scalars['String']['input']>>;
  zipCodeList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum MatchMailType {
  General = 'GENERAL',
  Prospection = 'PROSPECTION'
}

export type MatchedCompaniesConnection = {
  __typename?: 'MatchedCompaniesConnection';
  edges: Array<MatchedCompany>;
  pageInfo: PageInfo;
};

export type MatchedCompany = {
  __typename?: 'MatchedCompany';
  company: Company;
  enterpriseName: Scalars['String']['output'];
  inquiryResults: Array<MatchedInquiryResult>;
  maxScoreValue: Scalars['Float']['output'];
  propertiesCount: Scalars['Int']['output'];
  status: SearchStatusType;
  vatNumber: Scalars['String']['output'];
};

export type MatchedCompanyFilters = {
  companiesFilter?: InputMaybe<CompaniesFilter>;
  propertyFocusIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MatchedInquiryListingsInput = {
  inquiryID: Scalars['ID']['input'];
  listingFocusIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MatchedInquiryResult = {
  __typename?: 'MatchedInquiryResult';
  company?: Maybe<Company>;
  id: Scalars['ID']['output'];
  inquiry: Inquiry;
  interactions: Array<Interaction>;
  name: Scalars['String']['output'];
  propertiesCount: Scalars['Int']['output'];
  searchParams: InquiryParams;
  status: SearchStatusType;
};

export type MatchedListing = {
  __typename?: 'MatchedListing';
  focus: Scalars['Boolean']['output'];
  listing: PropertyListing;
  proposed: Scalars['Boolean']['output'];
  scoreValue: Scalars['Float']['output'];
};

export type MatchedListingsInput = {
  isActiveSearch?: Scalars['Boolean']['input'];
  listingFocusIDs: Array<Scalars['ID']['input']>;
  scoreThreshold?: Scalars['Float']['input'];
  vatNumber: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCapakeyInteraction: CapakeyInteraction;
  addOwnerToCapakey: CapakeyInfo;
  addParcelToSite: Scalars['Boolean']['output'];
  addUserToCapakey: CapakeyInfo;
  createCompany: Company;
  createCompanyFeedback: CompanyFeedback;
  createContact: Contact;
  createInquiry: Inquiry;
  createInteraction: Interaction;
  createListingFeedback: ListingFeedback;
  removeOwnerFromCapakey: CapakeyInfo;
  removeUserFromCapakey: CapakeyInfo;
  saveCompany: Company;
  sendActiveSearchMatchMails: Array<SendMatchMailResult>;
  sendMatchMails: Array<SendMatchMailResult>;
  updateCompany: Company;
  updateContact: Contact;
  updateInquiryStatus: SearchStatusType;
};


export type MutationAddCapakeyInteractionArgs = {
  input: CapakeyInteractionInput;
};


export type MutationAddOwnerToCapakeyArgs = {
  input: AddOwnerToCapakeyInput;
};


export type MutationAddParcelToSiteArgs = {
  capakey: Scalars['String']['input'];
  siteID: Scalars['ID']['input'];
};


export type MutationAddUserToCapakeyArgs = {
  input: AddUserToCapakeyInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompany;
};


export type MutationCreateCompanyFeedbackArgs = {
  input: CreateCompanyFeedback;
};


export type MutationCreateContactArgs = {
  input: CreateContact;
};


export type MutationCreateInquiryArgs = {
  model: CreateInquiry;
};


export type MutationCreateInteractionArgs = {
  input: CreateInteraction;
};


export type MutationCreateListingFeedbackArgs = {
  input: CreateListingFeedback;
};


export type MutationRemoveOwnerFromCapakeyArgs = {
  input: RemoveOwnerFromCapakeyInput;
};


export type MutationRemoveUserFromCapakeyArgs = {
  input: RemoveUserFromCapakeyInput;
};


export type MutationSaveCompanyArgs = {
  input: SaveCompany;
  vatNumber: Scalars['String']['input'];
};


export type MutationSendActiveSearchMatchMailsArgs = {
  input: SendActiveSearchMailConfig;
};


export type MutationSendMatchMailsArgs = {
  input: SendMatchMailConfig;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompany;
  vatNumber: Scalars['String']['input'];
};


export type MutationUpdateContactArgs = {
  contactId: Scalars['ID']['input'];
  input: UpdateContact;
};


export type MutationUpdateInquiryStatusArgs = {
  id: Scalars['ID']['input'];
  status: SearchStatusType;
};

export type NaceCode = {
  __typename?: 'NaceCode';
  label: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  naceCode: Scalars['String']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Int']['output']>;
  hasActive?: Maybe<Scalars['Boolean']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  latestOfActiveMatches?: Maybe<Scalars['Int']['output']>;
};

export type PageInfoImmoweb = {
  __typename?: 'PageInfoImmoweb';
  endCursor?: Maybe<Scalars['Int']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type PageInfoPropertyUnits = {
  __typename?: 'PageInfoPropertyUnits';
  endCursor?: Maybe<Scalars['Int']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type PaginatedCapakey = {
  __typename?: 'PaginatedCapakey';
  capakeys: Array<Capakey>;
  totalCount: Scalars['Int']['output'];
};

export type PaginationInput = {
  boundingBox?: InputMaybe<BoundingBoxCoordinates>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProRup = {
  __typename?: 'ProRup';
  fichelink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planid: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PropertiesFilter = {
  location?: InputMaybe<LocationFilter>;
  propertyBusinessSubTypeList?: InputMaybe<Array<PropertyBusinessSubType>>;
  propertyIdList?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  surfaceByBuildingAreaRange?: InputMaybe<SurfaceRange>;
  surfaceByPropertyUnitsAreaRange?: InputMaybe<SurfaceRange>;
  teaser?: InputMaybe<Scalars['Boolean']['input']>;
  transactionTypeList?: InputMaybe<Array<TransactionType>>;
};

export type PropertiesFilterImmoweb = {
  location?: InputMaybe<LocationFilterImmoweb>;
  propertyBusinessSubTypeList?: InputMaybe<Array<PropertyBusinessSubType>>;
  status?: InputMaybe<Array<PropertyStatusTypeImmoweb>>;
  surfaceByBuildingAreaRange?: InputMaybe<SurfaceRange>;
  transactionTypeList?: InputMaybe<Array<TransactionTypeImmoweb>>;
};

export enum PropertyBusinessSubType {
  Industry = 'INDUSTRY',
  Land = 'LAND',
  Office = 'OFFICE',
  Retail = 'RETAIL'
}

export type PropertyImage = {
  __typename?: 'PropertyImage';
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type PropertyImmoweb = {
  __typename?: 'PropertyImmoweb';
  agency?: Maybe<Scalars['String']['output']>;
  agencyWebsite?: Maybe<Scalars['String']['output']>;
  areaMax?: Maybe<Scalars['Float']['output']>;
  areaMin?: Maybe<Scalars['Float']['output']>;
  buildingArea?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  daysOnline?: Maybe<Scalars['Int']['output']>;
  displayUrl?: Maybe<Scalars['String']['output']>;
  displayUrlAgence?: Maybe<Scalars['String']['output']>;
  displayUrlLocation?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  plotArea?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  propertyId: Scalars['Int']['output'];
  source?: Maybe<Scalars['String']['output']>;
  status: PropertyStatusType;
  streetAndNr?: Maybe<Scalars['String']['output']>;
  subTypes?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type PropertyImmowebConnection = {
  __typename?: 'PropertyImmowebConnection';
  edges: Array<PropertyImmoweb>;
  pageInfo: PageInfoImmoweb;
};

export type PropertyListing = {
  __typename?: 'PropertyListing';
  Land: Scalars['Boolean']['output'];
  buildingArea?: Maybe<Scalars['Float']['output']>;
  buildingHeight?: Maybe<Scalars['Float']['output']>;
  caPaKey?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  images: Array<ListingImage>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  nearbyDescription?: Maybe<Scalars['String']['output']>;
  online: Scalars['Boolean']['output'];
  plotArea?: Maybe<Scalars['Float']['output']>;
  priceBuy?: Maybe<Scalars['Float']['output']>;
  priceRent?: Maybe<Scalars['Float']['output']>;
  priceTypeBuy?: Maybe<Scalars['String']['output']>;
  priceTypeRent?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: PropertyStatusType;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  subTypes?: Maybe<Array<Scalars['String']['output']>>;
  teaser: Scalars['Boolean']['output'];
  transactionType?: Maybe<Scalars['String']['output']>;
  units: Array<ListingUnit>;
  unitsGrossArea?: Maybe<Scalars['Float']['output']>;
  unitsGrossAreaMax?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum PropertyStatusType {
  Available = 'AVAILABLE',
  Option = 'OPTION'
}

export enum PropertyStatusTypeImmoweb {
  Available = 'AVAILABLE',
  SoldRented = 'SOLD_RENTED'
}

export type PropertyUnit = {
  __typename?: 'PropertyUnit';
  area: Scalars['Float']['output'];
  floor?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  listing: PropertyListing;
  listingID: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  unitsDescription: Scalars['String']['output'];
};

export type PropertyUnitsConnection = {
  __typename?: 'PropertyUnitsConnection';
  edges: Array<PropertyUnit>;
  pageInfo: PageInfoPropertyUnits;
};

export type PropertyUnitsFilter = {
  location?: InputMaybe<LocationFilter>;
  propertyBusinessSubTypeList?: InputMaybe<Array<PropertyBusinessSubType>>;
  surfaceByPropertyUnitsAreaRange?: InputMaybe<SurfaceRange>;
  transactionTypeList?: InputMaybe<Array<TransactionType>>;
};

export type Query = {
  __typename?: 'Query';
  autocomplete?: Maybe<Autocompleters>;
  capakeyInfo: CapakeyInfo;
  capakeyInteractionMarkers: Array<CapakeyInteractionMarker>;
  company?: Maybe<Company>;
  companyByID?: Maybe<Company>;
  contact?: Maybe<Array<Contact>>;
  contactById: Contact;
  filterCapakeys: PaginatedCapakey;
  inquiry?: Maybe<Inquiry>;
  interaction?: Maybe<Array<Interaction>>;
  matchedCompanyListings?: Maybe<Array<MatchedListing>>;
  matchedInquiryListings?: Maybe<Array<MatchedListing>>;
  matchedInquiryResult?: Maybe<MatchedInquiryResult>;
  me?: Maybe<User>;
  naceCode: Array<NaceCode>;
  searchCompanies: SearchedCompanies;
  searchCompaniesQuery: Array<Company>;
  /** @deprecated Use inquiry.contacts instead */
  searchContact?: Maybe<Array<Contact>>;
  searchContacts: Array<Contact>;
  searchMatchedCompanies?: Maybe<MatchedCompaniesConnection>;
  searchPropertiesImmoweb?: Maybe<PropertyImmowebConnection>;
  searchPropertyListings: Array<PropertyListing>;
  searchPropertyUnits?: Maybe<PropertyUnitsConnection>;
  sites?: Maybe<Array<Maybe<Site>>>;
  user?: Maybe<User>;
};


export type QueryCapakeyInfoArgs = {
  capakey: Scalars['String']['input'];
};


export type QueryCapakeyInteractionMarkersArgs = {
  filter: CapakeyMarkerFilter;
};


export type QueryCompanyArgs = {
  vatNumber: Scalars['String']['input'];
};


export type QueryCompanyByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactArgs = {
  email: Scalars['String']['input'];
};


export type QueryContactByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFilterCapakeysArgs = {
  filter: CapakeyFilter;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryInquiryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInteractionArgs = {
  vatNumber: Scalars['String']['input'];
};


export type QueryMatchedCompanyListingsArgs = {
  input: MatchedListingsInput;
};


export type QueryMatchedInquiryListingsArgs = {
  input: MatchedInquiryListingsInput;
};


export type QueryMatchedInquiryResultArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNaceCodeArgs = {
  search?: Scalars['String']['input'];
};


export type QuerySearchCompaniesArgs = {
  input: CompaniesFilter;
};


export type QuerySearchCompaniesQueryArgs = {
  input: SearchCompaniesInput;
};


export type QuerySearchContactArgs = {
  searchID: Scalars['ID']['input'];
};


export type QuerySearchContactsArgs = {
  query: SearchContactsInput;
};


export type QuerySearchMatchedCompaniesArgs = {
  after?: Scalars['Int']['input'];
  first?: Scalars['Int']['input'];
  includeActiveSearch?: Scalars['Boolean']['input'];
  includeZeroMatches?: Scalars['Boolean']['input'];
  input: MatchedCompanyFilters;
  latestActiveMatch?: InputMaybe<Scalars['Int']['input']>;
  scoreThreshold?: Scalars['Float']['input'];
};


export type QuerySearchPropertiesImmowebArgs = {
  after?: Scalars['Int']['input'];
  first?: Scalars['Int']['input'];
  input: PropertiesFilterImmoweb;
};


export type QuerySearchPropertyListingsArgs = {
  input: PropertiesFilter;
};


export type QuerySearchPropertyUnitsArgs = {
  after?: Scalars['Int']['input'];
  first?: Scalars['Int']['input'];
  input: PropertyUnitsFilter;
};


export type QuerySitesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export enum RealEstatePlayerType {
  Developer = 'DEVELOPER',
  Investor = 'INVESTOR',
  InvestorDeveloper = 'INVESTOR_DEVELOPER'
}

export type RemoveOwnerFromCapakeyInput = {
  capakey: Scalars['String']['input'];
  ownerID: Scalars['String']['input'];
};

export type RemoveUserFromCapakeyInput = {
  capakey: Scalars['String']['input'];
  userID: Scalars['String']['input'];
};

export type RevenueRange = {
  RevenueHigh: Scalars['Float']['input'];
  RevenueLow: Scalars['Float']['input'];
};

export type SaveCompany = {
  commercialName?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type SearchCompaniesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type SearchContactsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export enum SearchStatusType {
  ActiveSearch = 'ACTIVE_SEARCH',
  Double = 'DOUBLE',
  DoNotContact = 'DO_NOT_CONTACT',
  DoNotShow = 'DO_NOT_SHOW',
  LatentSearch = 'LATENT_SEARCH',
  NeverContacted = 'NEVER_CONTACTED',
  NoAnswer = 'NO_ANSWER',
  NoSearch = 'NO_SEARCH',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type SearchedCompanies = {
  __typename?: 'SearchedCompanies';
  companiesCount: Scalars['Int']['output'];
};

export type SendActiveSearchMailConfig = {
  from: EmailContactInput;
  inquiryID: Scalars['ID']['input'];
  intro: Scalars['String']['input'];
  listingIDs: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  tos: Array<Scalars['ID']['input']>;
};

export type SendMatchMailConfig = {
  from: EmailContactInput;
  inquiryID: Scalars['ID']['input'];
  intro: Scalars['String']['input'];
  listingIDs: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  template?: InputMaybe<MatchMailType>;
  tos: Array<Scalars['ID']['input']>;
};

export type SendMatchMailResult = {
  __typename?: 'SendMatchMailResult';
  contactID: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Site = {
  __typename?: 'Site';
  capakeys?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parcels: Array<CapakeyInfo>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SurfaceRange = {
  SurfaceHigh: Scalars['Float']['input'];
  SurfaceLow: Scalars['Float']['input'];
};

export enum TransactionType {
  Both = 'BOTH',
  Buy = 'BUY',
  Rent = 'RENT'
}

export enum TransactionTypeImmoweb {
  Buy = 'BUY',
  Rent = 'RENT'
}

export type UpdateCompany = {
  commercialName?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContact = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  unsubscribed?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  function: Scalars['String']['output'];
  hub: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image_url: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ZoneMappingType {
  Agriculture = 'Agriculture',
  AreaForSurfaceMineralExtraction = 'AreaForSurfaceMineralExtraction',
  BusinessActivity = 'BusinessActivity',
  CommunityAndUtilityServices = 'CommunityAndUtilityServices',
  Forest = 'Forest',
  Harbor = 'Harbor',
  Industry = 'Industry',
  Nature = 'Nature',
  NotRelevant = 'NotRelevant',
  Office = 'Office',
  OtherGreenAreas = 'OtherGreenAreas',
  OutsideOfSpatialDevelopmentPlan = 'OutsideOfSpatialDevelopmentPlan',
  Recreation = 'Recreation',
  Research = 'Research',
  Residential = 'Residential',
  SmeZone = 'SMEZone'
}

export type CreateSearchInteractionMutationVariables = Exact<{
  input: CreateInteraction;
}>;


export type CreateSearchInteractionMutation = { __typename?: 'Mutation', createInteraction: { __typename?: 'Interaction', id: string, description?: string | null, userName?: string | null, date?: string | null, type?: string | null } };

export type SendActiveMatchMailsMutationVariables = Exact<{
  input: SendActiveSearchMailConfig;
}>;


export type SendActiveMatchMailsMutation = { __typename?: 'Mutation', sendActiveSearchMatchMails: Array<{ __typename?: 'SendMatchMailResult', contactID: string, success: boolean, message: string }> };

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  contact: UpdateContact;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact: { __typename?: 'Contact', id: string, firstName?: string | null, lastName?: string | null, gender?: string | null, email: string, language?: string | null, function?: string | null } };

export type CreateContactMutationVariables = Exact<{
  contact: CreateContact;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'Contact', id: string, firstName?: string | null, lastName?: string | null, gender?: string | null, email: string, language?: string | null, function?: string | null, phone?: string | null } };

export type CreateInquiryMutationVariables = Exact<{
  input: CreateInquiry;
}>;


export type CreateInquiryMutation = { __typename?: 'Mutation', createInquiry: { __typename?: 'Inquiry', id: string } };

export type MatchedPropertiesQueryVariables = Exact<{
  vat: Scalars['String']['input'];
  propertyFocusIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type MatchedPropertiesQuery = { __typename?: 'Query', matchedCompanyListings?: Array<{ __typename?: 'MatchedListing', focus: boolean, scoreValue: number, proposed: boolean, listing: { __typename?: 'PropertyListing', id: string, name: string, latitude?: number | null, longitude?: number | null, streetName?: string | null, streetNumber?: string | null, zip?: string | null, city?: string | null, plotArea?: number | null, status: PropertyStatusType, transactionType?: string | null, unitsGrossArea?: number | null, unitsGrossAreaMax?: number | null, url?: string | null, online: boolean, images: Array<{ __typename?: 'ListingImage', id: string, url: string, width: number, height: number, previewUrl?: string | null }> } }> | null };

export type MatchedSearchPropertiesQueryVariables = Exact<{
  searchId: Scalars['ID']['input'];
  propertyFocusIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type MatchedSearchPropertiesQuery = { __typename?: 'Query', matchedInquiryListings?: Array<{ __typename?: 'MatchedListing', focus: boolean, scoreValue: number, proposed: boolean, listing: { __typename?: 'PropertyListing', id: string, name: string, latitude?: number | null, longitude?: number | null, streetName?: string | null, streetNumber?: string | null, zip?: string | null, city?: string | null, plotArea?: number | null, status: PropertyStatusType, transactionType?: string | null, unitsGrossArea?: number | null, unitsGrossAreaMax?: number | null, url?: string | null, online: boolean, images: Array<{ __typename?: 'ListingImage', id: string, url: string, width: number, height: number, previewUrl?: string | null }> } }> | null, inquiry?: { __typename?: 'Inquiry', historicalListings: Array<{ __typename?: 'PropertyListing', id: string, name: string, latitude?: number | null, longitude?: number | null, streetName?: string | null, streetNumber?: string | null, zip?: string | null, city?: string | null, plotArea?: number | null, status: PropertyStatusType, transactionType?: string | null, unitsGrossArea?: number | null, unitsGrossAreaMax?: number | null, url?: string | null, online: boolean, images: Array<{ __typename?: 'ListingImage', id: string, url: string, width: number, height: number, previewUrl?: string | null }> }> } | null };

export type CompanyInteractionsQueryVariables = Exact<{
  vat: Scalars['String']['input'];
}>;


export type CompanyInteractionsQuery = { __typename?: 'Query', interaction?: Array<{ __typename?: 'Interaction', id: string, description?: string | null, userName?: string | null, date?: string | null, type?: string | null }> | null };

export type CreateInteractionMutationVariables = Exact<{
  input: CreateInteraction;
}>;


export type CreateInteractionMutation = { __typename?: 'Mutation', createInteraction: { __typename?: 'Interaction', id: string, description?: string | null, userName?: string | null, date?: string | null, type?: string | null } };

export type SendMatchMailsMutationVariables = Exact<{
  input: SendMatchMailConfig;
}>;


export type SendMatchMailsMutation = { __typename?: 'Mutation', sendMatchMails: Array<{ __typename?: 'SendMatchMailResult', contactID: string, success: boolean, message: string }> };

export type CompanyMapsLinkQueryVariables = Exact<{
  vat: Scalars['String']['input'];
}>;


export type CompanyMapsLinkQuery = { __typename?: 'Query', company?: { __typename?: 'Company', enterpriseName?: string | null, commercialName?: string | null, branches: Array<{ __typename?: 'Branch', branchId: string, streetNl: string, houseNumber: string, municipalityNl: string, zip: string }> } | null };

export type ContactQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ContactQuery = { __typename?: 'Query', contactById: { __typename?: 'Contact', id: string, firstName?: string | null, lastName?: string | null, email: string, phone?: string | null, companies: Array<{ __typename?: 'Company', id?: string | null, enterpriseName?: string | null, commercialName?: string | null }> } };

export type ContactCompanyIdsQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ContactCompanyIdsQuery = { __typename?: 'Query', contact?: Array<{ __typename?: 'Contact', id: string, companies: Array<{ __typename?: 'Company', id?: string | null }> }> | null };

export type CreateCompanyFeedbackMutationVariables = Exact<{
  input: CreateCompanyFeedback;
}>;


export type CreateCompanyFeedbackMutation = { __typename?: 'Mutation', createCompanyFeedback: { __typename?: 'CompanyFeedback', createdAt?: string | null } };

export type CreateListingFeedbackMutationVariables = Exact<{
  input: CreateListingFeedback;
}>;


export type CreateListingFeedbackMutation = { __typename?: 'Mutation', createListingFeedback: { __typename?: 'ListingFeedback', createdAt?: string | null } };

export type CompanyInfoQueryVariables = Exact<{
  vat: Scalars['String']['input'];
}>;


export type CompanyInfoQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id?: string | null, enterpriseName?: string | null, commercialName?: string | null, website?: string | null, url: string, crmUrl: string, status: SearchStatusType, contacts: Array<{ __typename?: 'Contact', id: string, firstName?: string | null, lastName?: string | null, gender?: string | null, email: string, language?: string | null, function?: string | null }>, branches: Array<{ __typename?: 'Branch', branchId: string, streetNl: string, houseNumber: string, municipalityNl: string, zip: string }> } | null };

export type CompanySearchInfoQueryVariables = Exact<{
  searchId: Scalars['ID']['input'];
}>;


export type CompanySearchInfoQuery = { __typename?: 'Query', matchedInquiryResult?: { __typename?: 'MatchedInquiryResult', id: string, name: string, propertiesCount: number, status: SearchStatusType, company?: { __typename?: 'Company', id?: string | null, enterpriseName?: string | null, commercialName?: string | null, website?: string | null, url: string, crmUrl: string, status: SearchStatusType, contacts: Array<{ __typename?: 'Contact', id: string, firstName?: string | null, lastName?: string | null, gender?: string | null, email: string, language?: string | null, function?: string | null }>, branches: Array<{ __typename?: 'Branch', branchId: string, streetNl: string, houseNumber: string, municipalityNl: string, zip: string }> } | null, inquiry: { __typename?: 'Inquiry', negotiator?: { __typename?: 'User', name: string } | null, contacts: Array<{ __typename?: 'Contact', id: string, firstName?: string | null, lastName?: string | null, gender?: string | null, email: string, language?: string | null, function?: string | null }> }, interactions: Array<{ __typename?: 'Interaction', id: string, description?: string | null, userName?: string | null, date?: string | null, type?: string | null }>, searchParams: { __typename?: 'InquiryParams', locations: string, transactionType: TransactionType, propertySubType: string, minBuildingArea?: number | null, maxBuildingArea?: number | null } } | null };

export type SearchCompaniesQueryVariables = Exact<{
  input: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchCompaniesQuery = { __typename?: 'Query', searchCompaniesQuery: Array<{ __typename?: 'Company', id?: string | null, vatNumber: string, enterpriseName?: string | null, commercialName?: string | null }> };

export type SearchContactQueryVariables = Exact<{
  input: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchContactQuery = { __typename?: 'Query', searchContacts: Array<{ __typename?: 'Contact', id: string, firstName?: string | null, lastName?: string | null, email: string, companies: Array<{ __typename?: 'Company', id?: string | null, vatNumber: string, enterpriseName?: string | null, commercialName?: string | null }> }> };

export type CapakeysQueryVariables = Exact<{
  filter: CapakeyFilter;
  pagination: PaginationInput;
}>;


export type CapakeysQuery = { __typename?: 'Query', filterCapakeys: { __typename?: 'PaginatedCapakey', capakeys: Array<{ __typename?: 'Capakey', capakey: string, longitude: number, latitude: number, zoningMapping: ZoneMappingType, omatScore?: number | null }> } };

export type CapakeyInteractionMarkersQueryVariables = Exact<{
  filter: CapakeyMarkerFilter;
}>;


export type CapakeyInteractionMarkersQuery = { __typename?: 'Query', capakeyInteractionMarkers: Array<{ __typename?: 'CapakeyInteractionMarker', capakey: string, longitude: number, latitude: number }> };

export type AddOwnerToCapakeyMutationVariables = Exact<{
  input: AddOwnerToCapakeyInput;
}>;


export type AddOwnerToCapakeyMutation = { __typename?: 'Mutation', addOwnerToCapakey: { __typename?: 'CapakeyInfo', capakey: string } };

export type AddUserToCapakeyMutationVariables = Exact<{
  input: AddUserToCapakeyInput;
}>;


export type AddUserToCapakeyMutation = { __typename?: 'Mutation', addUserToCapakey: { __typename?: 'CapakeyInfo', capakey: string } };

export type AddParcelInteractionMutationVariables = Exact<{
  capakey: Scalars['String']['input'];
  comment: Scalars['String']['input'];
  followUpDate?: InputMaybe<Scalars['Time']['input']>;
}>;


export type AddParcelInteractionMutation = { __typename?: 'Mutation', addCapakeyInteraction: { __typename?: 'CapakeyInteraction', id: string } };

export type RemoveParcelOwnerMutationVariables = Exact<{
  capakey: Scalars['String']['input'];
  ownerID: Scalars['String']['input'];
}>;


export type RemoveParcelOwnerMutation = { __typename?: 'Mutation', removeOwnerFromCapakey: { __typename?: 'CapakeyInfo', capakey: string } };

export type ParcelRupInfoQueryVariables = Exact<{
  capakey: Scalars['String']['input'];
}>;


export type ParcelRupInfoQuery = { __typename?: 'Query', capakeyInfo: { __typename?: 'CapakeyInfo', gemRup?: Array<{ __typename?: 'GemRup', id: string, planid: string, name: string, fichelink: string, type: string } | null> | null, proRup?: Array<{ __typename?: 'ProRup', id: string, planid: string, name: string, fichelink: string, type: string } | null> | null, gwp?: Array<{ __typename?: 'Gwp', id: string, planid: string, name: string, fichelink: string, type: string } | null> | null, bpa?: Array<{ __typename?: 'Bpa', id: string, planid: string, name: string, fichelink: string, type: string } | null> | null, apa?: Array<{ __typename?: 'Apa', id: string, planid: string, name: string, fichelink: string, type: string } | null> | null } };

export type RemoveParcelUserMutationVariables = Exact<{
  capakey: Scalars['String']['input'];
  userID: Scalars['String']['input'];
}>;


export type RemoveParcelUserMutation = { __typename?: 'Mutation', removeUserFromCapakey: { __typename?: 'CapakeyInfo', capakey: string } };

export type ParcelInfoQueryVariables = Exact<{
  capakey: Scalars['String']['input'];
}>;


export type ParcelInfoQuery = { __typename?: 'Query', capakeyInfo: { __typename?: 'CapakeyInfo', capakey: string, omatScore?: number | null, parcelSize?: number | null, buildingArea?: number | null, buildingVolume?: number | null, crmID?: string | null, address?: { __typename?: 'Address', street: string, number: string, zip: string, city: string } | null, interactions: Array<{ __typename?: 'CapakeyInteraction', id: string, timestamp: string, comment: string, followUpDate?: string | null, user: { __typename?: 'User', id: string, name: string, image_url: string } }>, owners: Array<{ __typename?: 'Contact', id: string, firstName?: string | null, lastName?: string | null, email: string, phone?: string | null, companies: Array<{ __typename?: 'Company', id?: string | null, enterpriseName?: string | null, commercialName?: string | null }> }>, users: Array<{ __typename?: 'Company', id?: string | null, enterpriseName?: string | null, commercialName?: string | null, vatNumber: string }> } };

export type SitesQueryVariables = Exact<{ [key: string]: never; }>;


export type SitesQuery = { __typename?: 'Query', sites?: Array<{ __typename?: 'Site', id: string, name?: string | null, status?: string | null, latitude?: number | null, longitude?: number | null, capakeys?: Array<string | null> | null } | null> | null };

export type SiteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SiteQuery = { __typename?: 'Query', sites?: Array<{ __typename?: 'Site', id: string, name?: string | null, status?: string | null, latitude?: number | null, longitude?: number | null, capakeys?: Array<string | null> | null, parcels: Array<{ __typename?: 'CapakeyInfo', capakey: string, parcelSize?: number | null }> } | null> | null };

export type AddParcelToSiteMutationVariables = Exact<{
  capakey: Scalars['String']['input'];
  siteID: Scalars['ID']['input'];
}>;


export type AddParcelToSiteMutation = { __typename?: 'Mutation', addParcelToSite: boolean };

export type PropertyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PropertyQuery = { __typename?: 'Query', searchPropertyListings: Array<{ __typename?: 'PropertyListing', id: string, streetName?: string | null, streetNumber?: string | null, city?: string | null, zip?: string | null, name: string, plotArea?: number | null, latitude?: number | null, longitude?: number | null, transactionType?: string | null, buildingArea?: number | null, unitsGrossArea?: number | null, unitsGrossAreaMax?: number | null }> };

export type SearchPropertiesQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;


export type SearchPropertiesQuery = { __typename?: 'Query', searchPropertyListings: Array<{ __typename?: 'PropertyListing', id: string, name: string }> };

export type SaveCompanyInfoMutationVariables = Exact<{
  vat: Scalars['String']['input'];
  updates: SaveCompany;
}>;


export type SaveCompanyInfoMutation = { __typename?: 'Mutation', saveCompany: { __typename?: 'Company', id?: string | null, vatNumber: string, commercialName?: string | null, website?: string | null } };

export type SaveCompanySearchStatusMutationVariables = Exact<{
  searchId: Scalars['ID']['input'];
  status: SearchStatusType;
}>;


export type SaveCompanySearchStatusMutation = { __typename?: 'Mutation', updateInquiryStatus: SearchStatusType };

export type FilterCompaniesQueryVariables = Exact<{
  filter: CompaniesFilter;
}>;


export type FilterCompaniesQuery = { __typename?: 'Query', searchCompanies: { __typename?: 'SearchedCompanies', companiesCount: number } };

export type FilterPropertiesQueryVariables = Exact<{
  filter: PropertiesFilter;
}>;


export type FilterPropertiesQuery = { __typename?: 'Query', searchPropertyListings: Array<{ __typename?: 'PropertyListing', id: string, streetName?: string | null, streetNumber?: string | null, city?: string | null, zip?: string | null, name: string, plotArea?: number | null, latitude?: number | null, longitude?: number | null, transactionType?: string | null, buildingArea?: number | null, unitsGrossArea?: number | null, unitsGrossAreaMax?: number | null }> };

export type LocationsAutocompleteQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type LocationsAutocompleteQuery = { __typename?: 'Query', autocomplete?: { __typename?: 'Autocompleters', location?: { __typename?: 'AutocompleteSuggestions', provinces: { __typename?: 'AutocompleteSuggestionGroup', suggestions: Array<{ __typename?: 'AutocompleteSuggestion', label: string, value: string }> }, districts: { __typename?: 'AutocompleteSuggestionGroup', suggestions: Array<{ __typename?: 'AutocompleteSuggestion', label: string, value: string }> }, municipalities: { __typename?: 'AutocompleteSuggestionGroup', suggestions: Array<{ __typename?: 'AutocompleteSuggestion', label: string, value: string }> } } | null } | null };

export type ProactiveMatchesQueryVariables = Exact<{
  filter: MatchedCompanyFilters;
  first?: Scalars['Int']['input'];
  after?: Scalars['Int']['input'];
  latestActiveMatch?: InputMaybe<Scalars['Int']['input']>;
  includeActiveSearch?: Scalars['Boolean']['input'];
  includeZeroMatches?: Scalars['Boolean']['input'];
}>;


export type ProactiveMatchesQuery = { __typename?: 'Query', searchMatchedCompanies?: { __typename?: 'MatchedCompaniesConnection', edges: Array<{ __typename?: 'MatchedCompany', vatNumber: string, propertiesCount: number, maxScoreValue: number, enterpriseName: string, status: SearchStatusType, inquiryResults: Array<{ __typename?: 'MatchedInquiryResult', id: string, name: string, propertiesCount: number, status: SearchStatusType, inquiry: { __typename?: 'Inquiry', negotiator?: { __typename?: 'User', name: string } | null } }>, company: { __typename?: 'Company', negotiator?: { __typename?: 'User', name: string } | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: number | null, hasNextPage?: boolean | null, latestOfActiveMatches?: number | null } } | null };

export type NaceAutocompleteQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type NaceAutocompleteQuery = { __typename?: 'Query', autocomplete?: { __typename?: 'Autocompleters', naceCodes?: { __typename?: 'AutocompleteSuggestionGroup', suggestions: Array<{ __typename?: 'AutocompleteSuggestion', label: string, value: string }> } | null } | null };

export type NaceCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type NaceCodesQuery = { __typename?: 'Query', naceCode: Array<{ __typename?: 'NaceCode', level: number, naceCode: string, label: string }> };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, name: string, function: string, image_url: string, hub: string } | null };


export const CreateSearchInteractionDocument = `
    mutation CreateSearchInteraction($input: CreateInteraction!) {
  createInteraction(input: $input) {
    id
    description
    userName
    date
    type
  }
}
    `;
export const useCreateSearchInteractionMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSearchInteractionMutation, TError, CreateSearchInteractionMutationVariables, TContext>) =>
    useMutation<CreateSearchInteractionMutation, TError, CreateSearchInteractionMutationVariables, TContext>(
      ['CreateSearchInteraction'],
      (variables?: CreateSearchInteractionMutationVariables) => fetcher<CreateSearchInteractionMutation, CreateSearchInteractionMutationVariables>(CreateSearchInteractionDocument, variables)(),
      options
    );
useCreateSearchInteractionMutation.fetcher = (variables: CreateSearchInteractionMutationVariables, options?: RequestInit['headers']) => fetcher<CreateSearchInteractionMutation, CreateSearchInteractionMutationVariables>(CreateSearchInteractionDocument, variables, options);
export const SendActiveMatchMailsDocument = `
    mutation SendActiveMatchMails($input: SendActiveSearchMailConfig!) {
  sendActiveSearchMatchMails(input: $input) {
    contactID
    success
    message
  }
}
    `;
export const useSendActiveMatchMailsMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<SendActiveMatchMailsMutation, TError, SendActiveMatchMailsMutationVariables, TContext>) =>
    useMutation<SendActiveMatchMailsMutation, TError, SendActiveMatchMailsMutationVariables, TContext>(
      ['SendActiveMatchMails'],
      (variables?: SendActiveMatchMailsMutationVariables) => fetcher<SendActiveMatchMailsMutation, SendActiveMatchMailsMutationVariables>(SendActiveMatchMailsDocument, variables)(),
      options
    );
useSendActiveMatchMailsMutation.fetcher = (variables: SendActiveMatchMailsMutationVariables, options?: RequestInit['headers']) => fetcher<SendActiveMatchMailsMutation, SendActiveMatchMailsMutationVariables>(SendActiveMatchMailsDocument, variables, options);
export const UpdateContactDocument = `
    mutation UpdateContact($id: ID!, $contact: UpdateContact!) {
  updateContact(contactId: $id, input: $contact) {
    id
    firstName
    lastName
    gender
    email
    language
    function
  }
}
    `;
export const useUpdateContactMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateContactMutation, TError, UpdateContactMutationVariables, TContext>) =>
    useMutation<UpdateContactMutation, TError, UpdateContactMutationVariables, TContext>(
      ['UpdateContact'],
      (variables?: UpdateContactMutationVariables) => fetcher<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, variables)(),
      options
    );
useUpdateContactMutation.fetcher = (variables: UpdateContactMutationVariables, options?: RequestInit['headers']) => fetcher<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, variables, options);
export const CreateContactDocument = `
    mutation CreateContact($contact: CreateContact!) {
  createContact(input: $contact) {
    id
    firstName
    lastName
    gender
    email
    language
    function
    phone
  }
}
    `;
export const useCreateContactMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<CreateContactMutation, TError, CreateContactMutationVariables, TContext>) =>
    useMutation<CreateContactMutation, TError, CreateContactMutationVariables, TContext>(
      ['CreateContact'],
      (variables?: CreateContactMutationVariables) => fetcher<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, variables)(),
      options
    );
useCreateContactMutation.fetcher = (variables: CreateContactMutationVariables, options?: RequestInit['headers']) => fetcher<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, variables, options);
export const CreateInquiryDocument = `
    mutation CreateInquiry($input: CreateInquiry!) {
  createInquiry(model: $input) {
    id
  }
}
    `;
export const useCreateInquiryMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<CreateInquiryMutation, TError, CreateInquiryMutationVariables, TContext>) =>
    useMutation<CreateInquiryMutation, TError, CreateInquiryMutationVariables, TContext>(
      ['CreateInquiry'],
      (variables?: CreateInquiryMutationVariables) => fetcher<CreateInquiryMutation, CreateInquiryMutationVariables>(CreateInquiryDocument, variables)(),
      options
    );
useCreateInquiryMutation.fetcher = (variables: CreateInquiryMutationVariables, options?: RequestInit['headers']) => fetcher<CreateInquiryMutation, CreateInquiryMutationVariables>(CreateInquiryDocument, variables, options);
export const MatchedPropertiesDocument = `
    query MatchedProperties($vat: String!, $propertyFocusIds: [ID!]!) {
  matchedCompanyListings(
    input: {vatNumber: $vat, listingFocusIDs: $propertyFocusIds}
  ) {
    focus
    scoreValue
    proposed
    listing {
      id
      name
      latitude
      longitude
      streetName
      streetNumber
      zip
      city
      plotArea
      status
      transactionType
      unitsGrossArea
      unitsGrossAreaMax
      url
      online
      images {
        id
        url
        width
        height
        previewUrl
      }
    }
  }
}
    `;
export const useMatchedPropertiesQuery = <
      TData = MatchedPropertiesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: MatchedPropertiesQueryVariables,
      options?: UseQueryOptions<MatchedPropertiesQuery, TError, TData>
    ) =>
    useQuery<MatchedPropertiesQuery, TError, TData>(
      ['MatchedProperties', variables],
      fetcher<MatchedPropertiesQuery, MatchedPropertiesQueryVariables>(MatchedPropertiesDocument, variables),
      options
    );

useMatchedPropertiesQuery.getKey = (variables: MatchedPropertiesQueryVariables) => ['MatchedProperties', variables];
;

export const useInfiniteMatchedPropertiesQuery = <
      TData = MatchedPropertiesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: MatchedPropertiesQueryVariables,
      options?: UseInfiniteQueryOptions<MatchedPropertiesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MatchedPropertiesQuery, TError, TData>(
      ['MatchedProperties.infinite', variables],
      (metaData) => fetcher<MatchedPropertiesQuery, MatchedPropertiesQueryVariables>(MatchedPropertiesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteMatchedPropertiesQuery.getKey = (variables: MatchedPropertiesQueryVariables) => ['MatchedProperties.infinite', variables];
;

useMatchedPropertiesQuery.fetcher = (variables: MatchedPropertiesQueryVariables, options?: RequestInit['headers']) => fetcher<MatchedPropertiesQuery, MatchedPropertiesQueryVariables>(MatchedPropertiesDocument, variables, options);
export const MatchedSearchPropertiesDocument = `
    query MatchedSearchProperties($searchId: ID!, $propertyFocusIds: [ID!]!) {
  matchedInquiryListings(
    input: {inquiryID: $searchId, listingFocusIDs: $propertyFocusIds}
  ) {
    focus
    scoreValue
    proposed
    listing {
      id
      name
      latitude
      longitude
      streetName
      streetNumber
      zip
      city
      plotArea
      status
      transactionType
      unitsGrossArea
      unitsGrossAreaMax
      url
      online
      images {
        id
        url
        width
        height
        previewUrl
      }
    }
  }
  inquiry(id: $searchId) {
    historicalListings {
      id
      name
      latitude
      longitude
      streetName
      streetNumber
      zip
      city
      plotArea
      status
      transactionType
      unitsGrossArea
      unitsGrossAreaMax
      url
      online
      images {
        id
        url
        width
        height
        previewUrl
      }
    }
  }
}
    `;
export const useMatchedSearchPropertiesQuery = <
      TData = MatchedSearchPropertiesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: MatchedSearchPropertiesQueryVariables,
      options?: UseQueryOptions<MatchedSearchPropertiesQuery, TError, TData>
    ) =>
    useQuery<MatchedSearchPropertiesQuery, TError, TData>(
      ['MatchedSearchProperties', variables],
      fetcher<MatchedSearchPropertiesQuery, MatchedSearchPropertiesQueryVariables>(MatchedSearchPropertiesDocument, variables),
      options
    );

useMatchedSearchPropertiesQuery.getKey = (variables: MatchedSearchPropertiesQueryVariables) => ['MatchedSearchProperties', variables];
;

export const useInfiniteMatchedSearchPropertiesQuery = <
      TData = MatchedSearchPropertiesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: MatchedSearchPropertiesQueryVariables,
      options?: UseInfiniteQueryOptions<MatchedSearchPropertiesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MatchedSearchPropertiesQuery, TError, TData>(
      ['MatchedSearchProperties.infinite', variables],
      (metaData) => fetcher<MatchedSearchPropertiesQuery, MatchedSearchPropertiesQueryVariables>(MatchedSearchPropertiesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteMatchedSearchPropertiesQuery.getKey = (variables: MatchedSearchPropertiesQueryVariables) => ['MatchedSearchProperties.infinite', variables];
;

useMatchedSearchPropertiesQuery.fetcher = (variables: MatchedSearchPropertiesQueryVariables, options?: RequestInit['headers']) => fetcher<MatchedSearchPropertiesQuery, MatchedSearchPropertiesQueryVariables>(MatchedSearchPropertiesDocument, variables, options);
export const CompanyInteractionsDocument = `
    query CompanyInteractions($vat: String!) {
  interaction(vatNumber: $vat) {
    id
    description
    userName
    date
    type
  }
}
    `;
export const useCompanyInteractionsQuery = <
      TData = CompanyInteractionsQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CompanyInteractionsQueryVariables,
      options?: UseQueryOptions<CompanyInteractionsQuery, TError, TData>
    ) =>
    useQuery<CompanyInteractionsQuery, TError, TData>(
      ['CompanyInteractions', variables],
      fetcher<CompanyInteractionsQuery, CompanyInteractionsQueryVariables>(CompanyInteractionsDocument, variables),
      options
    );

useCompanyInteractionsQuery.getKey = (variables: CompanyInteractionsQueryVariables) => ['CompanyInteractions', variables];
;

export const useInfiniteCompanyInteractionsQuery = <
      TData = CompanyInteractionsQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CompanyInteractionsQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyInteractionsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyInteractionsQuery, TError, TData>(
      ['CompanyInteractions.infinite', variables],
      (metaData) => fetcher<CompanyInteractionsQuery, CompanyInteractionsQueryVariables>(CompanyInteractionsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteCompanyInteractionsQuery.getKey = (variables: CompanyInteractionsQueryVariables) => ['CompanyInteractions.infinite', variables];
;

useCompanyInteractionsQuery.fetcher = (variables: CompanyInteractionsQueryVariables, options?: RequestInit['headers']) => fetcher<CompanyInteractionsQuery, CompanyInteractionsQueryVariables>(CompanyInteractionsDocument, variables, options);
export const CreateInteractionDocument = `
    mutation CreateInteraction($input: CreateInteraction!) {
  createInteraction(input: $input) {
    id
    description
    userName
    date
    type
  }
}
    `;
export const useCreateInteractionMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<CreateInteractionMutation, TError, CreateInteractionMutationVariables, TContext>) =>
    useMutation<CreateInteractionMutation, TError, CreateInteractionMutationVariables, TContext>(
      ['CreateInteraction'],
      (variables?: CreateInteractionMutationVariables) => fetcher<CreateInteractionMutation, CreateInteractionMutationVariables>(CreateInteractionDocument, variables)(),
      options
    );
useCreateInteractionMutation.fetcher = (variables: CreateInteractionMutationVariables, options?: RequestInit['headers']) => fetcher<CreateInteractionMutation, CreateInteractionMutationVariables>(CreateInteractionDocument, variables, options);
export const SendMatchMailsDocument = `
    mutation SendMatchMails($input: SendMatchMailConfig!) {
  sendMatchMails(input: $input) {
    contactID
    success
    message
  }
}
    `;
export const useSendMatchMailsMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<SendMatchMailsMutation, TError, SendMatchMailsMutationVariables, TContext>) =>
    useMutation<SendMatchMailsMutation, TError, SendMatchMailsMutationVariables, TContext>(
      ['SendMatchMails'],
      (variables?: SendMatchMailsMutationVariables) => fetcher<SendMatchMailsMutation, SendMatchMailsMutationVariables>(SendMatchMailsDocument, variables)(),
      options
    );
useSendMatchMailsMutation.fetcher = (variables: SendMatchMailsMutationVariables, options?: RequestInit['headers']) => fetcher<SendMatchMailsMutation, SendMatchMailsMutationVariables>(SendMatchMailsDocument, variables, options);
export const CompanyMapsLinkDocument = `
    query CompanyMapsLink($vat: String!) {
  company(vatNumber: $vat) {
    enterpriseName
    commercialName
    branches {
      branchId
      streetNl
      houseNumber
      municipalityNl
      zip
    }
  }
}
    `;
export const useCompanyMapsLinkQuery = <
      TData = CompanyMapsLinkQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CompanyMapsLinkQueryVariables,
      options?: UseQueryOptions<CompanyMapsLinkQuery, TError, TData>
    ) =>
    useQuery<CompanyMapsLinkQuery, TError, TData>(
      ['CompanyMapsLink', variables],
      fetcher<CompanyMapsLinkQuery, CompanyMapsLinkQueryVariables>(CompanyMapsLinkDocument, variables),
      options
    );

useCompanyMapsLinkQuery.getKey = (variables: CompanyMapsLinkQueryVariables) => ['CompanyMapsLink', variables];
;

export const useInfiniteCompanyMapsLinkQuery = <
      TData = CompanyMapsLinkQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CompanyMapsLinkQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyMapsLinkQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyMapsLinkQuery, TError, TData>(
      ['CompanyMapsLink.infinite', variables],
      (metaData) => fetcher<CompanyMapsLinkQuery, CompanyMapsLinkQueryVariables>(CompanyMapsLinkDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteCompanyMapsLinkQuery.getKey = (variables: CompanyMapsLinkQueryVariables) => ['CompanyMapsLink.infinite', variables];
;

useCompanyMapsLinkQuery.fetcher = (variables: CompanyMapsLinkQueryVariables, options?: RequestInit['headers']) => fetcher<CompanyMapsLinkQuery, CompanyMapsLinkQueryVariables>(CompanyMapsLinkDocument, variables, options);
export const ContactDocument = `
    query contact($id: ID!) {
  contactById(id: $id) {
    id
    firstName
    lastName
    email
    phone
    companies {
      id
      enterpriseName
      commercialName
    }
  }
}
    `;
export const useContactQuery = <
      TData = ContactQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ContactQueryVariables,
      options?: UseQueryOptions<ContactQuery, TError, TData>
    ) =>
    useQuery<ContactQuery, TError, TData>(
      ['contact', variables],
      fetcher<ContactQuery, ContactQueryVariables>(ContactDocument, variables),
      options
    );

useContactQuery.getKey = (variables: ContactQueryVariables) => ['contact', variables];
;

export const useInfiniteContactQuery = <
      TData = ContactQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ContactQueryVariables,
      options?: UseInfiniteQueryOptions<ContactQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ContactQuery, TError, TData>(
      ['contact.infinite', variables],
      (metaData) => fetcher<ContactQuery, ContactQueryVariables>(ContactDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteContactQuery.getKey = (variables: ContactQueryVariables) => ['contact.infinite', variables];
;

useContactQuery.fetcher = (variables: ContactQueryVariables, options?: RequestInit['headers']) => fetcher<ContactQuery, ContactQueryVariables>(ContactDocument, variables, options);
export const ContactCompanyIdsDocument = `
    query contactCompanyIds($email: String!) {
  contact(email: $email) {
    id
    companies {
      id
    }
  }
}
    `;
export const useContactCompanyIdsQuery = <
      TData = ContactCompanyIdsQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ContactCompanyIdsQueryVariables,
      options?: UseQueryOptions<ContactCompanyIdsQuery, TError, TData>
    ) =>
    useQuery<ContactCompanyIdsQuery, TError, TData>(
      ['contactCompanyIds', variables],
      fetcher<ContactCompanyIdsQuery, ContactCompanyIdsQueryVariables>(ContactCompanyIdsDocument, variables),
      options
    );

useContactCompanyIdsQuery.getKey = (variables: ContactCompanyIdsQueryVariables) => ['contactCompanyIds', variables];
;

export const useInfiniteContactCompanyIdsQuery = <
      TData = ContactCompanyIdsQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ContactCompanyIdsQueryVariables,
      options?: UseInfiniteQueryOptions<ContactCompanyIdsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ContactCompanyIdsQuery, TError, TData>(
      ['contactCompanyIds.infinite', variables],
      (metaData) => fetcher<ContactCompanyIdsQuery, ContactCompanyIdsQueryVariables>(ContactCompanyIdsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteContactCompanyIdsQuery.getKey = (variables: ContactCompanyIdsQueryVariables) => ['contactCompanyIds.infinite', variables];
;

useContactCompanyIdsQuery.fetcher = (variables: ContactCompanyIdsQueryVariables, options?: RequestInit['headers']) => fetcher<ContactCompanyIdsQuery, ContactCompanyIdsQueryVariables>(ContactCompanyIdsDocument, variables, options);
export const CreateCompanyFeedbackDocument = `
    mutation createCompanyFeedback($input: CreateCompanyFeedback!) {
  createCompanyFeedback(input: $input) {
    createdAt
  }
}
    `;
export const useCreateCompanyFeedbackMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCompanyFeedbackMutation, TError, CreateCompanyFeedbackMutationVariables, TContext>) =>
    useMutation<CreateCompanyFeedbackMutation, TError, CreateCompanyFeedbackMutationVariables, TContext>(
      ['createCompanyFeedback'],
      (variables?: CreateCompanyFeedbackMutationVariables) => fetcher<CreateCompanyFeedbackMutation, CreateCompanyFeedbackMutationVariables>(CreateCompanyFeedbackDocument, variables)(),
      options
    );
useCreateCompanyFeedbackMutation.fetcher = (variables: CreateCompanyFeedbackMutationVariables, options?: RequestInit['headers']) => fetcher<CreateCompanyFeedbackMutation, CreateCompanyFeedbackMutationVariables>(CreateCompanyFeedbackDocument, variables, options);
export const CreateListingFeedbackDocument = `
    mutation createListingFeedback($input: CreateListingFeedback!) {
  createListingFeedback(input: $input) {
    createdAt
  }
}
    `;
export const useCreateListingFeedbackMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<CreateListingFeedbackMutation, TError, CreateListingFeedbackMutationVariables, TContext>) =>
    useMutation<CreateListingFeedbackMutation, TError, CreateListingFeedbackMutationVariables, TContext>(
      ['createListingFeedback'],
      (variables?: CreateListingFeedbackMutationVariables) => fetcher<CreateListingFeedbackMutation, CreateListingFeedbackMutationVariables>(CreateListingFeedbackDocument, variables)(),
      options
    );
useCreateListingFeedbackMutation.fetcher = (variables: CreateListingFeedbackMutationVariables, options?: RequestInit['headers']) => fetcher<CreateListingFeedbackMutation, CreateListingFeedbackMutationVariables>(CreateListingFeedbackDocument, variables, options);
export const CompanyInfoDocument = `
    query CompanyInfo($vat: String!) {
  company(vatNumber: $vat) {
    id
    enterpriseName
    commercialName
    website
    url
    crmUrl
    status
    contacts {
      id
      firstName
      lastName
      gender
      email
      language
      function
    }
    branches {
      branchId
      streetNl
      houseNumber
      municipalityNl
      zip
    }
  }
}
    `;
export const useCompanyInfoQuery = <
      TData = CompanyInfoQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CompanyInfoQueryVariables,
      options?: UseQueryOptions<CompanyInfoQuery, TError, TData>
    ) =>
    useQuery<CompanyInfoQuery, TError, TData>(
      ['CompanyInfo', variables],
      fetcher<CompanyInfoQuery, CompanyInfoQueryVariables>(CompanyInfoDocument, variables),
      options
    );

useCompanyInfoQuery.getKey = (variables: CompanyInfoQueryVariables) => ['CompanyInfo', variables];
;

export const useInfiniteCompanyInfoQuery = <
      TData = CompanyInfoQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CompanyInfoQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyInfoQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyInfoQuery, TError, TData>(
      ['CompanyInfo.infinite', variables],
      (metaData) => fetcher<CompanyInfoQuery, CompanyInfoQueryVariables>(CompanyInfoDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteCompanyInfoQuery.getKey = (variables: CompanyInfoQueryVariables) => ['CompanyInfo.infinite', variables];
;

useCompanyInfoQuery.fetcher = (variables: CompanyInfoQueryVariables, options?: RequestInit['headers']) => fetcher<CompanyInfoQuery, CompanyInfoQueryVariables>(CompanyInfoDocument, variables, options);
export const CompanySearchInfoDocument = `
    query CompanySearchInfo($searchId: ID!) {
  matchedInquiryResult(id: $searchId) {
    id
    name
    propertiesCount
    status
    company {
      id
      enterpriseName
      commercialName
      website
      url
      crmUrl
      status
      contacts {
        id
        firstName
        lastName
        gender
        email
        language
        function
      }
      branches {
        branchId
        streetNl
        houseNumber
        municipalityNl
        zip
      }
    }
    inquiry {
      negotiator {
        name
      }
      contacts {
        id
        firstName
        lastName
        gender
        email
        language
        function
      }
    }
    interactions {
      id
      description
      userName
      date
      type
    }
    searchParams {
      locations
      transactionType
      propertySubType
      minBuildingArea
      maxBuildingArea
    }
  }
}
    `;
export const useCompanySearchInfoQuery = <
      TData = CompanySearchInfoQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CompanySearchInfoQueryVariables,
      options?: UseQueryOptions<CompanySearchInfoQuery, TError, TData>
    ) =>
    useQuery<CompanySearchInfoQuery, TError, TData>(
      ['CompanySearchInfo', variables],
      fetcher<CompanySearchInfoQuery, CompanySearchInfoQueryVariables>(CompanySearchInfoDocument, variables),
      options
    );

useCompanySearchInfoQuery.getKey = (variables: CompanySearchInfoQueryVariables) => ['CompanySearchInfo', variables];
;

export const useInfiniteCompanySearchInfoQuery = <
      TData = CompanySearchInfoQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CompanySearchInfoQueryVariables,
      options?: UseInfiniteQueryOptions<CompanySearchInfoQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanySearchInfoQuery, TError, TData>(
      ['CompanySearchInfo.infinite', variables],
      (metaData) => fetcher<CompanySearchInfoQuery, CompanySearchInfoQueryVariables>(CompanySearchInfoDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteCompanySearchInfoQuery.getKey = (variables: CompanySearchInfoQueryVariables) => ['CompanySearchInfo.infinite', variables];
;

useCompanySearchInfoQuery.fetcher = (variables: CompanySearchInfoQueryVariables, options?: RequestInit['headers']) => fetcher<CompanySearchInfoQuery, CompanySearchInfoQueryVariables>(CompanySearchInfoDocument, variables, options);
export const SearchCompaniesDocument = `
    query SearchCompanies($input: String!, $limit: Int, $offset: Int) {
  searchCompaniesQuery(input: {query: $input, limit: $limit, offset: $offset}) {
    id
    vatNumber
    enterpriseName
    commercialName
  }
}
    `;
export const useSearchCompaniesQuery = <
      TData = SearchCompaniesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: SearchCompaniesQueryVariables,
      options?: UseQueryOptions<SearchCompaniesQuery, TError, TData>
    ) =>
    useQuery<SearchCompaniesQuery, TError, TData>(
      ['SearchCompanies', variables],
      fetcher<SearchCompaniesQuery, SearchCompaniesQueryVariables>(SearchCompaniesDocument, variables),
      options
    );

useSearchCompaniesQuery.getKey = (variables: SearchCompaniesQueryVariables) => ['SearchCompanies', variables];
;

export const useInfiniteSearchCompaniesQuery = <
      TData = SearchCompaniesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: SearchCompaniesQueryVariables,
      options?: UseInfiniteQueryOptions<SearchCompaniesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SearchCompaniesQuery, TError, TData>(
      ['SearchCompanies.infinite', variables],
      (metaData) => fetcher<SearchCompaniesQuery, SearchCompaniesQueryVariables>(SearchCompaniesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSearchCompaniesQuery.getKey = (variables: SearchCompaniesQueryVariables) => ['SearchCompanies.infinite', variables];
;

useSearchCompaniesQuery.fetcher = (variables: SearchCompaniesQueryVariables, options?: RequestInit['headers']) => fetcher<SearchCompaniesQuery, SearchCompaniesQueryVariables>(SearchCompaniesDocument, variables, options);
export const SearchContactDocument = `
    query SearchContact($input: String!, $limit: Int, $offset: Int) {
  searchContacts(query: {query: $input, limit: $limit, offset: $offset}) {
    id
    firstName
    lastName
    email
    companies {
      id
      vatNumber
      enterpriseName
      commercialName
    }
  }
}
    `;
export const useSearchContactQuery = <
      TData = SearchContactQuery,
      TError = GraphQLErrorResponse
    >(
      variables: SearchContactQueryVariables,
      options?: UseQueryOptions<SearchContactQuery, TError, TData>
    ) =>
    useQuery<SearchContactQuery, TError, TData>(
      ['SearchContact', variables],
      fetcher<SearchContactQuery, SearchContactQueryVariables>(SearchContactDocument, variables),
      options
    );

useSearchContactQuery.getKey = (variables: SearchContactQueryVariables) => ['SearchContact', variables];
;

export const useInfiniteSearchContactQuery = <
      TData = SearchContactQuery,
      TError = GraphQLErrorResponse
    >(
      variables: SearchContactQueryVariables,
      options?: UseInfiniteQueryOptions<SearchContactQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SearchContactQuery, TError, TData>(
      ['SearchContact.infinite', variables],
      (metaData) => fetcher<SearchContactQuery, SearchContactQueryVariables>(SearchContactDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSearchContactQuery.getKey = (variables: SearchContactQueryVariables) => ['SearchContact.infinite', variables];
;

useSearchContactQuery.fetcher = (variables: SearchContactQueryVariables, options?: RequestInit['headers']) => fetcher<SearchContactQuery, SearchContactQueryVariables>(SearchContactDocument, variables, options);
export const CapakeysDocument = `
    query Capakeys($filter: CapakeyFilter!, $pagination: PaginationInput!) {
  filterCapakeys(filter: $filter, pagination: $pagination) {
    capakeys {
      capakey
      longitude
      latitude
      zoningMapping
      omatScore
    }
  }
}
    `;
export const useCapakeysQuery = <
      TData = CapakeysQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CapakeysQueryVariables,
      options?: UseQueryOptions<CapakeysQuery, TError, TData>
    ) =>
    useQuery<CapakeysQuery, TError, TData>(
      ['Capakeys', variables],
      fetcher<CapakeysQuery, CapakeysQueryVariables>(CapakeysDocument, variables),
      options
    );

useCapakeysQuery.getKey = (variables: CapakeysQueryVariables) => ['Capakeys', variables];
;

export const useInfiniteCapakeysQuery = <
      TData = CapakeysQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CapakeysQueryVariables,
      options?: UseInfiniteQueryOptions<CapakeysQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CapakeysQuery, TError, TData>(
      ['Capakeys.infinite', variables],
      (metaData) => fetcher<CapakeysQuery, CapakeysQueryVariables>(CapakeysDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteCapakeysQuery.getKey = (variables: CapakeysQueryVariables) => ['Capakeys.infinite', variables];
;

useCapakeysQuery.fetcher = (variables: CapakeysQueryVariables, options?: RequestInit['headers']) => fetcher<CapakeysQuery, CapakeysQueryVariables>(CapakeysDocument, variables, options);
export const CapakeyInteractionMarkersDocument = `
    query CapakeyInteractionMarkers($filter: CapakeyMarkerFilter!) {
  capakeyInteractionMarkers(filter: $filter) {
    capakey
    longitude
    latitude
  }
}
    `;
export const useCapakeyInteractionMarkersQuery = <
      TData = CapakeyInteractionMarkersQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CapakeyInteractionMarkersQueryVariables,
      options?: UseQueryOptions<CapakeyInteractionMarkersQuery, TError, TData>
    ) =>
    useQuery<CapakeyInteractionMarkersQuery, TError, TData>(
      ['CapakeyInteractionMarkers', variables],
      fetcher<CapakeyInteractionMarkersQuery, CapakeyInteractionMarkersQueryVariables>(CapakeyInteractionMarkersDocument, variables),
      options
    );

useCapakeyInteractionMarkersQuery.getKey = (variables: CapakeyInteractionMarkersQueryVariables) => ['CapakeyInteractionMarkers', variables];
;

export const useInfiniteCapakeyInteractionMarkersQuery = <
      TData = CapakeyInteractionMarkersQuery,
      TError = GraphQLErrorResponse
    >(
      variables: CapakeyInteractionMarkersQueryVariables,
      options?: UseInfiniteQueryOptions<CapakeyInteractionMarkersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CapakeyInteractionMarkersQuery, TError, TData>(
      ['CapakeyInteractionMarkers.infinite', variables],
      (metaData) => fetcher<CapakeyInteractionMarkersQuery, CapakeyInteractionMarkersQueryVariables>(CapakeyInteractionMarkersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteCapakeyInteractionMarkersQuery.getKey = (variables: CapakeyInteractionMarkersQueryVariables) => ['CapakeyInteractionMarkers.infinite', variables];
;

useCapakeyInteractionMarkersQuery.fetcher = (variables: CapakeyInteractionMarkersQueryVariables, options?: RequestInit['headers']) => fetcher<CapakeyInteractionMarkersQuery, CapakeyInteractionMarkersQueryVariables>(CapakeyInteractionMarkersDocument, variables, options);
export const AddOwnerToCapakeyDocument = `
    mutation addOwnerToCapakey($input: AddOwnerToCapakeyInput!) {
  addOwnerToCapakey(input: $input) {
    capakey
  }
}
    `;
export const useAddOwnerToCapakeyMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<AddOwnerToCapakeyMutation, TError, AddOwnerToCapakeyMutationVariables, TContext>) =>
    useMutation<AddOwnerToCapakeyMutation, TError, AddOwnerToCapakeyMutationVariables, TContext>(
      ['addOwnerToCapakey'],
      (variables?: AddOwnerToCapakeyMutationVariables) => fetcher<AddOwnerToCapakeyMutation, AddOwnerToCapakeyMutationVariables>(AddOwnerToCapakeyDocument, variables)(),
      options
    );
useAddOwnerToCapakeyMutation.fetcher = (variables: AddOwnerToCapakeyMutationVariables, options?: RequestInit['headers']) => fetcher<AddOwnerToCapakeyMutation, AddOwnerToCapakeyMutationVariables>(AddOwnerToCapakeyDocument, variables, options);
export const AddUserToCapakeyDocument = `
    mutation addUserToCapakey($input: AddUserToCapakeyInput!) {
  addUserToCapakey(input: $input) {
    capakey
  }
}
    `;
export const useAddUserToCapakeyMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<AddUserToCapakeyMutation, TError, AddUserToCapakeyMutationVariables, TContext>) =>
    useMutation<AddUserToCapakeyMutation, TError, AddUserToCapakeyMutationVariables, TContext>(
      ['addUserToCapakey'],
      (variables?: AddUserToCapakeyMutationVariables) => fetcher<AddUserToCapakeyMutation, AddUserToCapakeyMutationVariables>(AddUserToCapakeyDocument, variables)(),
      options
    );
useAddUserToCapakeyMutation.fetcher = (variables: AddUserToCapakeyMutationVariables, options?: RequestInit['headers']) => fetcher<AddUserToCapakeyMutation, AddUserToCapakeyMutationVariables>(AddUserToCapakeyDocument, variables, options);
export const AddParcelInteractionDocument = `
    mutation AddParcelInteraction($capakey: String!, $comment: String!, $followUpDate: Time) {
  addCapakeyInteraction(
    input: {capakey: $capakey, comment: $comment, followUpDate: $followUpDate}
  ) {
    id
  }
}
    `;
export const useAddParcelInteractionMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<AddParcelInteractionMutation, TError, AddParcelInteractionMutationVariables, TContext>) =>
    useMutation<AddParcelInteractionMutation, TError, AddParcelInteractionMutationVariables, TContext>(
      ['AddParcelInteraction'],
      (variables?: AddParcelInteractionMutationVariables) => fetcher<AddParcelInteractionMutation, AddParcelInteractionMutationVariables>(AddParcelInteractionDocument, variables)(),
      options
    );
useAddParcelInteractionMutation.fetcher = (variables: AddParcelInteractionMutationVariables, options?: RequestInit['headers']) => fetcher<AddParcelInteractionMutation, AddParcelInteractionMutationVariables>(AddParcelInteractionDocument, variables, options);
export const RemoveParcelOwnerDocument = `
    mutation removeParcelOwner($capakey: String!, $ownerID: String!) {
  removeOwnerFromCapakey(input: {capakey: $capakey, ownerID: $ownerID}) {
    capakey
  }
}
    `;
export const useRemoveParcelOwnerMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveParcelOwnerMutation, TError, RemoveParcelOwnerMutationVariables, TContext>) =>
    useMutation<RemoveParcelOwnerMutation, TError, RemoveParcelOwnerMutationVariables, TContext>(
      ['removeParcelOwner'],
      (variables?: RemoveParcelOwnerMutationVariables) => fetcher<RemoveParcelOwnerMutation, RemoveParcelOwnerMutationVariables>(RemoveParcelOwnerDocument, variables)(),
      options
    );
useRemoveParcelOwnerMutation.fetcher = (variables: RemoveParcelOwnerMutationVariables, options?: RequestInit['headers']) => fetcher<RemoveParcelOwnerMutation, RemoveParcelOwnerMutationVariables>(RemoveParcelOwnerDocument, variables, options);
export const ParcelRupInfoDocument = `
    query ParcelRupInfo($capakey: String!) {
  capakeyInfo(capakey: $capakey) {
    gemRup {
      id
      planid
      name
      fichelink
      type
    }
    proRup {
      id
      planid
      name
      fichelink
      type
    }
    gwp {
      id
      planid
      name
      fichelink
      type
    }
    bpa {
      id
      planid
      name
      fichelink
      type
    }
    apa {
      id
      planid
      name
      fichelink
      type
    }
  }
}
    `;
export const useParcelRupInfoQuery = <
      TData = ParcelRupInfoQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ParcelRupInfoQueryVariables,
      options?: UseQueryOptions<ParcelRupInfoQuery, TError, TData>
    ) =>
    useQuery<ParcelRupInfoQuery, TError, TData>(
      ['ParcelRupInfo', variables],
      fetcher<ParcelRupInfoQuery, ParcelRupInfoQueryVariables>(ParcelRupInfoDocument, variables),
      options
    );

useParcelRupInfoQuery.getKey = (variables: ParcelRupInfoQueryVariables) => ['ParcelRupInfo', variables];
;

export const useInfiniteParcelRupInfoQuery = <
      TData = ParcelRupInfoQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ParcelRupInfoQueryVariables,
      options?: UseInfiniteQueryOptions<ParcelRupInfoQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ParcelRupInfoQuery, TError, TData>(
      ['ParcelRupInfo.infinite', variables],
      (metaData) => fetcher<ParcelRupInfoQuery, ParcelRupInfoQueryVariables>(ParcelRupInfoDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteParcelRupInfoQuery.getKey = (variables: ParcelRupInfoQueryVariables) => ['ParcelRupInfo.infinite', variables];
;

useParcelRupInfoQuery.fetcher = (variables: ParcelRupInfoQueryVariables, options?: RequestInit['headers']) => fetcher<ParcelRupInfoQuery, ParcelRupInfoQueryVariables>(ParcelRupInfoDocument, variables, options);
export const RemoveParcelUserDocument = `
    mutation removeParcelUser($capakey: String!, $userID: String!) {
  removeUserFromCapakey(input: {capakey: $capakey, userID: $userID}) {
    capakey
  }
}
    `;
export const useRemoveParcelUserMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveParcelUserMutation, TError, RemoveParcelUserMutationVariables, TContext>) =>
    useMutation<RemoveParcelUserMutation, TError, RemoveParcelUserMutationVariables, TContext>(
      ['removeParcelUser'],
      (variables?: RemoveParcelUserMutationVariables) => fetcher<RemoveParcelUserMutation, RemoveParcelUserMutationVariables>(RemoveParcelUserDocument, variables)(),
      options
    );
useRemoveParcelUserMutation.fetcher = (variables: RemoveParcelUserMutationVariables, options?: RequestInit['headers']) => fetcher<RemoveParcelUserMutation, RemoveParcelUserMutationVariables>(RemoveParcelUserDocument, variables, options);
export const ParcelInfoDocument = `
    query ParcelInfo($capakey: String!) {
  capakeyInfo(capakey: $capakey) {
    capakey
    omatScore
    address {
      street
      number
      zip
      city
    }
    parcelSize
    buildingArea
    buildingVolume
    crmID
    interactions {
      id
      timestamp
      comment
      followUpDate
      user {
        id
        name
        image_url
      }
    }
    owners {
      id
      firstName
      lastName
      email
      phone
      companies {
        id
        enterpriseName
        commercialName
      }
    }
    users {
      id
      enterpriseName
      commercialName
      vatNumber
    }
  }
}
    `;
export const useParcelInfoQuery = <
      TData = ParcelInfoQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ParcelInfoQueryVariables,
      options?: UseQueryOptions<ParcelInfoQuery, TError, TData>
    ) =>
    useQuery<ParcelInfoQuery, TError, TData>(
      ['ParcelInfo', variables],
      fetcher<ParcelInfoQuery, ParcelInfoQueryVariables>(ParcelInfoDocument, variables),
      options
    );

useParcelInfoQuery.getKey = (variables: ParcelInfoQueryVariables) => ['ParcelInfo', variables];
;

export const useInfiniteParcelInfoQuery = <
      TData = ParcelInfoQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ParcelInfoQueryVariables,
      options?: UseInfiniteQueryOptions<ParcelInfoQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ParcelInfoQuery, TError, TData>(
      ['ParcelInfo.infinite', variables],
      (metaData) => fetcher<ParcelInfoQuery, ParcelInfoQueryVariables>(ParcelInfoDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteParcelInfoQuery.getKey = (variables: ParcelInfoQueryVariables) => ['ParcelInfo.infinite', variables];
;

useParcelInfoQuery.fetcher = (variables: ParcelInfoQueryVariables, options?: RequestInit['headers']) => fetcher<ParcelInfoQuery, ParcelInfoQueryVariables>(ParcelInfoDocument, variables, options);
export const SitesDocument = `
    query Sites {
  sites {
    id
    name
    status
    latitude
    longitude
    capakeys
  }
}
    `;
export const useSitesQuery = <
      TData = SitesQuery,
      TError = GraphQLErrorResponse
    >(
      variables?: SitesQueryVariables,
      options?: UseQueryOptions<SitesQuery, TError, TData>
    ) =>
    useQuery<SitesQuery, TError, TData>(
      variables === undefined ? ['Sites'] : ['Sites', variables],
      fetcher<SitesQuery, SitesQueryVariables>(SitesDocument, variables),
      options
    );

useSitesQuery.getKey = (variables?: SitesQueryVariables) => variables === undefined ? ['Sites'] : ['Sites', variables];
;

export const useInfiniteSitesQuery = <
      TData = SitesQuery,
      TError = GraphQLErrorResponse
    >(
      variables?: SitesQueryVariables,
      options?: UseInfiniteQueryOptions<SitesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SitesQuery, TError, TData>(
      variables === undefined ? ['Sites.infinite'] : ['Sites.infinite', variables],
      (metaData) => fetcher<SitesQuery, SitesQueryVariables>(SitesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSitesQuery.getKey = (variables?: SitesQueryVariables) => variables === undefined ? ['Sites.infinite'] : ['Sites.infinite', variables];
;

useSitesQuery.fetcher = (variables?: SitesQueryVariables, options?: RequestInit['headers']) => fetcher<SitesQuery, SitesQueryVariables>(SitesDocument, variables, options);
export const SiteDocument = `
    query Site($id: ID!) {
  sites(ids: [$id]) {
    id
    name
    status
    latitude
    longitude
    capakeys
    parcels {
      capakey
      parcelSize
    }
  }
}
    `;
export const useSiteQuery = <
      TData = SiteQuery,
      TError = GraphQLErrorResponse
    >(
      variables: SiteQueryVariables,
      options?: UseQueryOptions<SiteQuery, TError, TData>
    ) =>
    useQuery<SiteQuery, TError, TData>(
      ['Site', variables],
      fetcher<SiteQuery, SiteQueryVariables>(SiteDocument, variables),
      options
    );

useSiteQuery.getKey = (variables: SiteQueryVariables) => ['Site', variables];
;

export const useInfiniteSiteQuery = <
      TData = SiteQuery,
      TError = GraphQLErrorResponse
    >(
      variables: SiteQueryVariables,
      options?: UseInfiniteQueryOptions<SiteQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SiteQuery, TError, TData>(
      ['Site.infinite', variables],
      (metaData) => fetcher<SiteQuery, SiteQueryVariables>(SiteDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSiteQuery.getKey = (variables: SiteQueryVariables) => ['Site.infinite', variables];
;

useSiteQuery.fetcher = (variables: SiteQueryVariables, options?: RequestInit['headers']) => fetcher<SiteQuery, SiteQueryVariables>(SiteDocument, variables, options);
export const AddParcelToSiteDocument = `
    mutation addParcelToSite($capakey: String!, $siteID: ID!) {
  addParcelToSite(capakey: $capakey, siteID: $siteID)
}
    `;
export const useAddParcelToSiteMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<AddParcelToSiteMutation, TError, AddParcelToSiteMutationVariables, TContext>) =>
    useMutation<AddParcelToSiteMutation, TError, AddParcelToSiteMutationVariables, TContext>(
      ['addParcelToSite'],
      (variables?: AddParcelToSiteMutationVariables) => fetcher<AddParcelToSiteMutation, AddParcelToSiteMutationVariables>(AddParcelToSiteDocument, variables)(),
      options
    );
useAddParcelToSiteMutation.fetcher = (variables: AddParcelToSiteMutationVariables, options?: RequestInit['headers']) => fetcher<AddParcelToSiteMutation, AddParcelToSiteMutationVariables>(AddParcelToSiteDocument, variables, options);
export const PropertyDocument = `
    query Property($id: ID!) {
  searchPropertyListings(input: {propertyIdList: [$id]}) {
    id
    streetName
    streetNumber
    city
    zip
    name
    plotArea
    latitude
    longitude
    transactionType
    buildingArea
    unitsGrossArea
    unitsGrossAreaMax
  }
}
    `;
export const usePropertyQuery = <
      TData = PropertyQuery,
      TError = GraphQLErrorResponse
    >(
      variables: PropertyQueryVariables,
      options?: UseQueryOptions<PropertyQuery, TError, TData>
    ) =>
    useQuery<PropertyQuery, TError, TData>(
      ['Property', variables],
      fetcher<PropertyQuery, PropertyQueryVariables>(PropertyDocument, variables),
      options
    );

usePropertyQuery.getKey = (variables: PropertyQueryVariables) => ['Property', variables];
;

export const useInfinitePropertyQuery = <
      TData = PropertyQuery,
      TError = GraphQLErrorResponse
    >(
      variables: PropertyQueryVariables,
      options?: UseInfiniteQueryOptions<PropertyQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PropertyQuery, TError, TData>(
      ['Property.infinite', variables],
      (metaData) => fetcher<PropertyQuery, PropertyQueryVariables>(PropertyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfinitePropertyQuery.getKey = (variables: PropertyQueryVariables) => ['Property.infinite', variables];
;

usePropertyQuery.fetcher = (variables: PropertyQueryVariables, options?: RequestInit['headers']) => fetcher<PropertyQuery, PropertyQueryVariables>(PropertyDocument, variables, options);
export const SearchPropertiesDocument = `
    query SearchProperties($input: String!) {
  searchPropertyListings(input: {searchQuery: $input}) {
    id
    name
  }
}
    `;
export const useSearchPropertiesQuery = <
      TData = SearchPropertiesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: SearchPropertiesQueryVariables,
      options?: UseQueryOptions<SearchPropertiesQuery, TError, TData>
    ) =>
    useQuery<SearchPropertiesQuery, TError, TData>(
      ['SearchProperties', variables],
      fetcher<SearchPropertiesQuery, SearchPropertiesQueryVariables>(SearchPropertiesDocument, variables),
      options
    );

useSearchPropertiesQuery.getKey = (variables: SearchPropertiesQueryVariables) => ['SearchProperties', variables];
;

export const useInfiniteSearchPropertiesQuery = <
      TData = SearchPropertiesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: SearchPropertiesQueryVariables,
      options?: UseInfiniteQueryOptions<SearchPropertiesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SearchPropertiesQuery, TError, TData>(
      ['SearchProperties.infinite', variables],
      (metaData) => fetcher<SearchPropertiesQuery, SearchPropertiesQueryVariables>(SearchPropertiesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSearchPropertiesQuery.getKey = (variables: SearchPropertiesQueryVariables) => ['SearchProperties.infinite', variables];
;

useSearchPropertiesQuery.fetcher = (variables: SearchPropertiesQueryVariables, options?: RequestInit['headers']) => fetcher<SearchPropertiesQuery, SearchPropertiesQueryVariables>(SearchPropertiesDocument, variables, options);
export const SaveCompanyInfoDocument = `
    mutation saveCompanyInfo($vat: String!, $updates: SaveCompany!) {
  saveCompany(vatNumber: $vat, input: $updates) {
    id
    vatNumber
    commercialName
    website
  }
}
    `;
export const useSaveCompanyInfoMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<SaveCompanyInfoMutation, TError, SaveCompanyInfoMutationVariables, TContext>) =>
    useMutation<SaveCompanyInfoMutation, TError, SaveCompanyInfoMutationVariables, TContext>(
      ['saveCompanyInfo'],
      (variables?: SaveCompanyInfoMutationVariables) => fetcher<SaveCompanyInfoMutation, SaveCompanyInfoMutationVariables>(SaveCompanyInfoDocument, variables)(),
      options
    );
useSaveCompanyInfoMutation.fetcher = (variables: SaveCompanyInfoMutationVariables, options?: RequestInit['headers']) => fetcher<SaveCompanyInfoMutation, SaveCompanyInfoMutationVariables>(SaveCompanyInfoDocument, variables, options);
export const SaveCompanySearchStatusDocument = `
    mutation saveCompanySearchStatus($searchId: ID!, $status: SearchStatusType!) {
  updateInquiryStatus(id: $searchId, status: $status)
}
    `;
export const useSaveCompanySearchStatusMutation = <
      TError = GraphQLErrorResponse,
      TContext = unknown
    >(options?: UseMutationOptions<SaveCompanySearchStatusMutation, TError, SaveCompanySearchStatusMutationVariables, TContext>) =>
    useMutation<SaveCompanySearchStatusMutation, TError, SaveCompanySearchStatusMutationVariables, TContext>(
      ['saveCompanySearchStatus'],
      (variables?: SaveCompanySearchStatusMutationVariables) => fetcher<SaveCompanySearchStatusMutation, SaveCompanySearchStatusMutationVariables>(SaveCompanySearchStatusDocument, variables)(),
      options
    );
useSaveCompanySearchStatusMutation.fetcher = (variables: SaveCompanySearchStatusMutationVariables, options?: RequestInit['headers']) => fetcher<SaveCompanySearchStatusMutation, SaveCompanySearchStatusMutationVariables>(SaveCompanySearchStatusDocument, variables, options);
export const FilterCompaniesDocument = `
    query FilterCompanies($filter: CompaniesFilter!) {
  searchCompanies(input: $filter) {
    companiesCount
  }
}
    `;
export const useFilterCompaniesQuery = <
      TData = FilterCompaniesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: FilterCompaniesQueryVariables,
      options?: UseQueryOptions<FilterCompaniesQuery, TError, TData>
    ) =>
    useQuery<FilterCompaniesQuery, TError, TData>(
      ['FilterCompanies', variables],
      fetcher<FilterCompaniesQuery, FilterCompaniesQueryVariables>(FilterCompaniesDocument, variables),
      options
    );

useFilterCompaniesQuery.getKey = (variables: FilterCompaniesQueryVariables) => ['FilterCompanies', variables];
;

export const useInfiniteFilterCompaniesQuery = <
      TData = FilterCompaniesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: FilterCompaniesQueryVariables,
      options?: UseInfiniteQueryOptions<FilterCompaniesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<FilterCompaniesQuery, TError, TData>(
      ['FilterCompanies.infinite', variables],
      (metaData) => fetcher<FilterCompaniesQuery, FilterCompaniesQueryVariables>(FilterCompaniesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteFilterCompaniesQuery.getKey = (variables: FilterCompaniesQueryVariables) => ['FilterCompanies.infinite', variables];
;

useFilterCompaniesQuery.fetcher = (variables: FilterCompaniesQueryVariables, options?: RequestInit['headers']) => fetcher<FilterCompaniesQuery, FilterCompaniesQueryVariables>(FilterCompaniesDocument, variables, options);
export const FilterPropertiesDocument = `
    query FilterProperties($filter: PropertiesFilter!) {
  searchPropertyListings(input: $filter) {
    id
    streetName
    streetNumber
    city
    zip
    name
    plotArea
    latitude
    longitude
    transactionType
    buildingArea
    unitsGrossArea
    unitsGrossAreaMax
  }
}
    `;
export const useFilterPropertiesQuery = <
      TData = FilterPropertiesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: FilterPropertiesQueryVariables,
      options?: UseQueryOptions<FilterPropertiesQuery, TError, TData>
    ) =>
    useQuery<FilterPropertiesQuery, TError, TData>(
      ['FilterProperties', variables],
      fetcher<FilterPropertiesQuery, FilterPropertiesQueryVariables>(FilterPropertiesDocument, variables),
      options
    );

useFilterPropertiesQuery.getKey = (variables: FilterPropertiesQueryVariables) => ['FilterProperties', variables];
;

export const useInfiniteFilterPropertiesQuery = <
      TData = FilterPropertiesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: FilterPropertiesQueryVariables,
      options?: UseInfiniteQueryOptions<FilterPropertiesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<FilterPropertiesQuery, TError, TData>(
      ['FilterProperties.infinite', variables],
      (metaData) => fetcher<FilterPropertiesQuery, FilterPropertiesQueryVariables>(FilterPropertiesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteFilterPropertiesQuery.getKey = (variables: FilterPropertiesQueryVariables) => ['FilterProperties.infinite', variables];
;

useFilterPropertiesQuery.fetcher = (variables: FilterPropertiesQueryVariables, options?: RequestInit['headers']) => fetcher<FilterPropertiesQuery, FilterPropertiesQueryVariables>(FilterPropertiesDocument, variables, options);
export const LocationsAutocompleteDocument = `
    query LocationsAutocomplete($search: String!) {
  autocomplete {
    location(search: $search) {
      provinces {
        suggestions {
          label
          value
        }
      }
      districts {
        suggestions {
          label
          value
        }
      }
      municipalities {
        suggestions {
          label
          value
        }
      }
    }
  }
}
    `;
export const useLocationsAutocompleteQuery = <
      TData = LocationsAutocompleteQuery,
      TError = GraphQLErrorResponse
    >(
      variables: LocationsAutocompleteQueryVariables,
      options?: UseQueryOptions<LocationsAutocompleteQuery, TError, TData>
    ) =>
    useQuery<LocationsAutocompleteQuery, TError, TData>(
      ['LocationsAutocomplete', variables],
      fetcher<LocationsAutocompleteQuery, LocationsAutocompleteQueryVariables>(LocationsAutocompleteDocument, variables),
      options
    );

useLocationsAutocompleteQuery.getKey = (variables: LocationsAutocompleteQueryVariables) => ['LocationsAutocomplete', variables];
;

export const useInfiniteLocationsAutocompleteQuery = <
      TData = LocationsAutocompleteQuery,
      TError = GraphQLErrorResponse
    >(
      variables: LocationsAutocompleteQueryVariables,
      options?: UseInfiniteQueryOptions<LocationsAutocompleteQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LocationsAutocompleteQuery, TError, TData>(
      ['LocationsAutocomplete.infinite', variables],
      (metaData) => fetcher<LocationsAutocompleteQuery, LocationsAutocompleteQueryVariables>(LocationsAutocompleteDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLocationsAutocompleteQuery.getKey = (variables: LocationsAutocompleteQueryVariables) => ['LocationsAutocomplete.infinite', variables];
;

useLocationsAutocompleteQuery.fetcher = (variables: LocationsAutocompleteQueryVariables, options?: RequestInit['headers']) => fetcher<LocationsAutocompleteQuery, LocationsAutocompleteQueryVariables>(LocationsAutocompleteDocument, variables, options);
export const ProactiveMatchesDocument = `
    query ProactiveMatches($filter: MatchedCompanyFilters!, $first: Int! = 20, $after: Int! = 0, $latestActiveMatch: Int, $includeActiveSearch: Boolean! = false, $includeZeroMatches: Boolean! = false) {
  searchMatchedCompanies(
    input: $filter
    first: $first
    after: $after
    includeZeroMatches: $includeZeroMatches
    includeActiveSearch: $includeActiveSearch
    latestActiveMatch: $latestActiveMatch
  ) {
    edges {
      vatNumber
      propertiesCount
      maxScoreValue
      enterpriseName
      status
      inquiryResults {
        id
        name
        propertiesCount
        status
        inquiry {
          negotiator {
            name
          }
        }
      }
      company {
        negotiator {
          name
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      latestOfActiveMatches
    }
  }
}
    `;
export const useProactiveMatchesQuery = <
      TData = ProactiveMatchesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ProactiveMatchesQueryVariables,
      options?: UseQueryOptions<ProactiveMatchesQuery, TError, TData>
    ) =>
    useQuery<ProactiveMatchesQuery, TError, TData>(
      ['ProactiveMatches', variables],
      fetcher<ProactiveMatchesQuery, ProactiveMatchesQueryVariables>(ProactiveMatchesDocument, variables),
      options
    );

useProactiveMatchesQuery.getKey = (variables: ProactiveMatchesQueryVariables) => ['ProactiveMatches', variables];
;

export const useInfiniteProactiveMatchesQuery = <
      TData = ProactiveMatchesQuery,
      TError = GraphQLErrorResponse
    >(
      variables: ProactiveMatchesQueryVariables,
      options?: UseInfiniteQueryOptions<ProactiveMatchesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ProactiveMatchesQuery, TError, TData>(
      ['ProactiveMatches.infinite', variables],
      (metaData) => fetcher<ProactiveMatchesQuery, ProactiveMatchesQueryVariables>(ProactiveMatchesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteProactiveMatchesQuery.getKey = (variables: ProactiveMatchesQueryVariables) => ['ProactiveMatches.infinite', variables];
;

useProactiveMatchesQuery.fetcher = (variables: ProactiveMatchesQueryVariables, options?: RequestInit['headers']) => fetcher<ProactiveMatchesQuery, ProactiveMatchesQueryVariables>(ProactiveMatchesDocument, variables, options);
export const NaceAutocompleteDocument = `
    query NaceAutocomplete($search: String!) {
  autocomplete {
    naceCodes(search: $search) {
      suggestions {
        label
        value
      }
    }
  }
}
    `;
export const useNaceAutocompleteQuery = <
      TData = NaceAutocompleteQuery,
      TError = GraphQLErrorResponse
    >(
      variables: NaceAutocompleteQueryVariables,
      options?: UseQueryOptions<NaceAutocompleteQuery, TError, TData>
    ) =>
    useQuery<NaceAutocompleteQuery, TError, TData>(
      ['NaceAutocomplete', variables],
      fetcher<NaceAutocompleteQuery, NaceAutocompleteQueryVariables>(NaceAutocompleteDocument, variables),
      options
    );

useNaceAutocompleteQuery.getKey = (variables: NaceAutocompleteQueryVariables) => ['NaceAutocomplete', variables];
;

export const useInfiniteNaceAutocompleteQuery = <
      TData = NaceAutocompleteQuery,
      TError = GraphQLErrorResponse
    >(
      variables: NaceAutocompleteQueryVariables,
      options?: UseInfiniteQueryOptions<NaceAutocompleteQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<NaceAutocompleteQuery, TError, TData>(
      ['NaceAutocomplete.infinite', variables],
      (metaData) => fetcher<NaceAutocompleteQuery, NaceAutocompleteQueryVariables>(NaceAutocompleteDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteNaceAutocompleteQuery.getKey = (variables: NaceAutocompleteQueryVariables) => ['NaceAutocomplete.infinite', variables];
;

useNaceAutocompleteQuery.fetcher = (variables: NaceAutocompleteQueryVariables, options?: RequestInit['headers']) => fetcher<NaceAutocompleteQuery, NaceAutocompleteQueryVariables>(NaceAutocompleteDocument, variables, options);
export const NaceCodesDocument = `
    query NaceCodes {
  naceCode {
    level
    naceCode
    label
  }
}
    `;
export const useNaceCodesQuery = <
      TData = NaceCodesQuery,
      TError = GraphQLErrorResponse
    >(
      variables?: NaceCodesQueryVariables,
      options?: UseQueryOptions<NaceCodesQuery, TError, TData>
    ) =>
    useQuery<NaceCodesQuery, TError, TData>(
      variables === undefined ? ['NaceCodes'] : ['NaceCodes', variables],
      fetcher<NaceCodesQuery, NaceCodesQueryVariables>(NaceCodesDocument, variables),
      options
    );

useNaceCodesQuery.getKey = (variables?: NaceCodesQueryVariables) => variables === undefined ? ['NaceCodes'] : ['NaceCodes', variables];
;

export const useInfiniteNaceCodesQuery = <
      TData = NaceCodesQuery,
      TError = GraphQLErrorResponse
    >(
      variables?: NaceCodesQueryVariables,
      options?: UseInfiniteQueryOptions<NaceCodesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<NaceCodesQuery, TError, TData>(
      variables === undefined ? ['NaceCodes.infinite'] : ['NaceCodes.infinite', variables],
      (metaData) => fetcher<NaceCodesQuery, NaceCodesQueryVariables>(NaceCodesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteNaceCodesQuery.getKey = (variables?: NaceCodesQueryVariables) => variables === undefined ? ['NaceCodes.infinite'] : ['NaceCodes.infinite', variables];
;

useNaceCodesQuery.fetcher = (variables?: NaceCodesQueryVariables, options?: RequestInit['headers']) => fetcher<NaceCodesQuery, NaceCodesQueryVariables>(NaceCodesDocument, variables, options);
export const CurrentUserDocument = `
    query CurrentUser {
  me {
    id
    email
    name
    function
    image_url
    hub
  }
}
    `;
export const useCurrentUserQuery = <
      TData = CurrentUserQuery,
      TError = GraphQLErrorResponse
    >(
      variables?: CurrentUserQueryVariables,
      options?: UseQueryOptions<CurrentUserQuery, TError, TData>
    ) =>
    useQuery<CurrentUserQuery, TError, TData>(
      variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables],
      fetcher<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, variables),
      options
    );

useCurrentUserQuery.getKey = (variables?: CurrentUserQueryVariables) => variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables];
;

export const useInfiniteCurrentUserQuery = <
      TData = CurrentUserQuery,
      TError = GraphQLErrorResponse
    >(
      variables?: CurrentUserQueryVariables,
      options?: UseInfiniteQueryOptions<CurrentUserQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CurrentUserQuery, TError, TData>(
      variables === undefined ? ['CurrentUser.infinite'] : ['CurrentUser.infinite', variables],
      (metaData) => fetcher<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteCurrentUserQuery.getKey = (variables?: CurrentUserQueryVariables) => variables === undefined ? ['CurrentUser.infinite'] : ['CurrentUser.infinite', variables];
;

useCurrentUserQuery.fetcher = (variables?: CurrentUserQueryVariables, options?: RequestInit['headers']) => fetcher<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, variables, options);