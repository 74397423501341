const ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV;
const IS_PRODUCTION: boolean = !!ENV && ENV === 'production';
const IS_DEVELOPMENT: boolean = !IS_PRODUCTION;
const DEPLOYED_BRANCH: string | undefined = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;
const IS_LOCALHOST: boolean = !DEPLOYED_BRANCH;
const IS_HEXAGON: boolean = DEPLOYED_BRANCH === 'hexagon';

export const environment = {
  ENV,
  IS_PRODUCTION,
  IS_DEVELOPMENT,
  IS_LOCALHOST,
  DEPLOYED_BRANCH,
  IS_HEXAGON,
};

export default environment;
